import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './OrderFailed.component.scss';
import infoBox from '../../assets/images/info-box.svg';

import AppContext from '../../context/app/app.context';


const OrderFailed = () => {

    const { openModal } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    /** component will-mount */
    useEffect(() => {
        if (!location.state?.isRedirectViaCheckoutPage) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onContactUs = () => {
        openModal('contact');
    }

    const goBackToBooking = () => {
        navigate('/booking');
    }


    return (
        <div className="order-failed-container">

            <div className='order-failed-wrap'>
                <div className='order-failed-title-wrap'>
                    <div className='order-failed-title-icon'>
                        <svg width="78" height="78" viewBox="0 0 78 78" fill="none">
                            <path d="M63.7354 0H14.2949L0 14.2949V63.7354L14.2949 78H63.7354L78 63.7354V14.2949L63.7354 0Z" fill="#DAFF00" />
                            <path d="M24.842 61V57.16L34.802 40.12L25.202 23.74V19.9H32.882L39.482 32.08H39.722L46.322 19.9H53.762V23.74L44.162 40.12L54.182 57.16V61H46.502L39.542 48.16H39.362L32.282 61H24.842Z" fill="#282727" />
                        </svg>
                    </div>
                    <h4 className='order-failed-title'>your order was unsuccessful</h4>
                </div>
                <p className='order-failed-desc'>
                    Sorry, your order didn’t go through. Please try again.
                </p>

                <button className='order-failed-go-back-btn' type='button' onClick={goBackToBooking}>back to bookings</button>
            </div>

            <div className='order-failed-help-wrapper'>
                <img className='order-failed-help-icon' src={infoBox} draggable="false" alt='' />
                <h4 className='order-failed-help-link'>need more help? <span className='link' onClick={onContactUs}>contact us</span></h4>
            </div>

        </div>
    )
}

export default OrderFailed;