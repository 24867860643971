import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import './Checkout.component.scss';

import AppContext from "../../context/app/app.context";
import { getCurrentUser } from "../../services/user.service";
import { bookingSlotDate } from "../../services/utils.service";
import { removeOrderOverviewItem } from "../../services/order.service";
import GuestRegistration from "../guest-registration/GuestRegistration.component";


function Checkout() {

    const { appConfig } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const elements = useRef();
    const stripe = useRef();
    const checkoutDetailsRef = useRef();
    const basketItemDisplayNames = {
        FULL: 'Full Pitch',
        HALF: 'Half Pitch',
        THIRD: 'Third Pitch',
        MUSIC_STUDIO: 'Music Studio',
        GAMES_ROOM: 'Games Room',
    };

    const [orderOverview, setOrderOverview] = useState({
        list: [],
        total: 0
    });

    const [isTAndCsTouched, setIsTAndCsTouched] = useState(false);
    const [tAndCs, setTAndCs] = useState(false);
    const [isRequestAlive, setIsRequestAlive] = useState(false);

    const [guestUserSubmitted, setGuestUserSubmitted] = useState(false);

    // Fetches a payment intent and captures the client secret
    async function initializeStripe() {
        const { clientSecret } = checkoutDetailsRef.current;

        if (!clientSecret) {
            return;
        }

        const appearance = {
            theme: 'stripe',
            variables: {
                colorPrimary: '#daff00',
                colorBackground: '#1e1e1e',
                colorText: '#ffffff',
            },
            // labels: 'floating'
        };
        elements.current = stripe.current.elements({ appearance, clientSecret });
        const paymentElementOptions = {
            layout: 'tabs',
        };

        const paymentElement = elements.current.create('payment', paymentElementOptions);
        paymentElement.mount('#payment-element');
    };

    // Fetches the payment intent status after payment submission
    async function checkPaymentStatus() {
        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) return;

        const { paymentIntent } = await stripe.current.retrievePaymentIntent(clientSecret);

        switch (paymentIntent.status) {
            case "succeeded":
                showMessage("Payment succeeded!");
                break;
            case "processing":
                showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                showMessage("Your payment was not successful, please try again.");
                break;
            default:
                showMessage("Something went wrong.");
                break;
        }
    }

    // ------- UI helpers -------
    function showMessage(messageText) {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        // setTimeout(function () {
        //     messageContainer.classList.add("hidden");
        //     messageContainer.textContent = "";
        // }, 10000);
    }
    function hideMessage() {
        const messageContainer = document.querySelector("#payment-message");
        if (!messageContainer.classList?.contains('hidden')) {
            messageContainer.classList.add("hidden");
            messageContainer.textContent = "";
        }
    }

    // Show a spinner on payment submission
    function setLoading(isLoading) {
        if (isLoading) {
            // Disable the button and show a spinner
            document.querySelector("#submit").disabled = true;
            document.querySelector("#spinner").classList.remove("hidden");
            document.querySelector("#button-text").classList.add("hidden");
        } else {
            document.querySelector("#submit").disabled = false;
            document.querySelector("#spinner").classList.add("hidden");
            document.querySelector("#button-text").classList.remove("hidden");
        }
    }

    async function handleOnCheckoutSubmit(e) {
        e.preventDefault();
        window.hideBeforeunloadWarning = true;
        setLoading(true);
        hideMessage();
        setTimeout(async () => {

            if (!isTAndCsTouched) {
                setIsTAndCsTouched(true);
            }
            if (!tAndCs || !elements.current) {
                setLoading(false);
                return;
            }

            const { error } = await stripe.current.confirmPayment({
                elements: elements.current,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: `${appConfig?.clientUrl}/order-success`,
                    receipt_email: getCurrentUser()?.email || null,
                },
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "validation_error") {
                showMessage(error.message);
            } else if (error.type === "card_error") {
                navigate('/order-failed', { state: { isRedirectViaCheckoutPage: true } });
            } else {
                showMessage("An unexpected error occurred.");
            }
            setLoading(false);
            window.hideBeforeunloadWarning = false;
        }, 100);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location.state) {
            stripe.current = window.Stripe(appConfig.STRIPE_PUBLISH_KEY);
            checkoutDetailsRef.current = location.state;

            const { orderItems, ...locationState } = location.state;
            for (let i = 0; i < orderItems.length; i++) {
                const orderItem = orderItems[i];
                orderItem.previewDate = bookingSlotDate(orderItem.date);
            }

            setOrderOverview({
                list: orderItems,
                ...locationState
            });
            window.history.replaceState({}, document.title, location.pathname);
        } else {
            navigate('/booking');
        }

        /** when we receive hideBeforeunloadWarning in true state on component did-mount it means payment is DONE */
        window.hideBeforeunloadWarning = false;
        const unloadCallback = (event) => {
            if (!window.hideBeforeunloadWarning) {
                event.preventDefault();
                event.returnValue = ""; // Gecko + IE
                return "";              // Webkit, Safari, Chrome etc.
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function init() {
            await initializeStripe();
            await checkPaymentStatus();
        }
        if (location.state && !checkoutDetailsRef.current?.isGuestUser) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (guestUserSubmitted) {
            setTimeout(() => {
                initializeStripe();
            }, 300);
        }
    }, [guestUserSubmitted]);

    const onRemoveToOrderOverview = (i) => {
        if (isRequestAlive) { return; }

        const itemToRemove = orderOverview.list[i];
        const body = {
            orderId: checkoutDetailsRef.current.orderId,
            assetType: itemToRemove.assetType,
            date: itemToRemove.date.slice(0, 10),
            startTime: itemToRemove.startTime
        };

        setIsRequestAlive(true);
        removeOrderOverviewItem(body).then((res) => {
            if (res.data.code === "OK") {
                const response = res.data.data;

                // discard the payment process and redirect to the book page
                if (response?.canceled || !response?.clientSecret) {
                    navigate('/booking');
                    return;
                } else if (response?.clientSecret) {
                    orderOverview.orderAmount = orderOverview.orderAmount - itemToRemove.totalPrice;
                    orderOverview.amountToPay = response.amountToPay;
                    orderOverview.additionalDiscountAmount = response?.additionalDiscountAmount;
                    orderOverview.discountAmount = response?.discountAmount;
                    orderOverview.list.splice(i, 1);

                    setOrderOverview({ ...orderOverview });
                    checkoutDetailsRef.current.clientSecret = response.clientSecret;
                    if (!checkoutDetailsRef.current?.isGuestUser) {
                        initializeStripe();
                    }
                }
            }
            setIsRequestAlive(false);
        }).catch((err) => {
            setIsRequestAlive(false);
            console.log('Error while removing order overview item', err);
        });
    };


    return (
        <div className="checkout-container">

            <div className="checkout-wrapper">

                <div className="checkout-items-container">
                    <div className='checkout-items-wrap'>
                        <div className='booking-basket-wrapper'>
                            <h4 className='booking-basket-title'>order overview</h4>

                            <div className='booking-basket-items'>

                                {orderOverview.list?.map((orderItem, i) => {
                                    return <div className='booking-basket-item' key={i}>
                                        <div className='booking-basket-item-detail'>{`${orderItem?.previewDate} //`}</div>
                                        <div className='booking-basket-item-detail'>{`${orderItem?.startTime}-${orderItem?.endTime} //`}</div>
                                        <div className='booking-basket-item-detail'>{`${basketItemDisplayNames[orderItem?.assetType]} //`}</div>
                                        {orderItem.assetType === "MUSIC_STUDIO" && <div className='booking-basket-item-detail'>{`${orderItem?.peoples} People //`}</div>}
                                        {orderItem.assetType === "GAMES_ROOM" && <div className='booking-basket-item-detail'>{`£${orderItem?.unitPrice} X ${orderItem?.consoles}(Console${orderItem?.consoles > 1 ? 's' : ''}) //`}</div>}
                                        <div className='booking-basket-item-action-wrapper'>
                                            {orderItem.assetType === "GAMES_ROOM" ?
                                                <div className='booking-basket-item-price'>{`£${orderItem?.unitPrice * orderItem?.consoles}`}</div>
                                                :
                                                <div className='booking-basket-item-price'>{`£${orderItem?.totalPrice}`}</div>
                                            }
                                            <div className='booking-basket-item-remove' onClick={() => { onRemoveToOrderOverview(i) }}>x remove</div>
                                        </div>
                                    </div>
                                })}

                                {/* {!orderOverview.list?.length &&
                                    <div className='booking-basket-item'>
                                        <div className='booking-basket-empty-box'>
                                            <h4 className='booking-basket-empty-box-msg'>your order overview is empty.</h4>
                                        </div>
                                    </div>
                                } */}
                            </div>

                            {(appConfig?.membershipApproved && orderOverview?.discountAmount > 0) && (
                                <div className='booking-basket-discount-wrap bb-0'>
                                    <h4 className='booking-basket-discount-label price'>{`£${orderOverview.discountAmount?.toFixed(2)}`}</h4>
                                    <h4 className='booking-basket-discount-label'>membership discount</h4>
                                </div>
                            )}

                            {(orderOverview?.additionalDiscountAmount > 0) && (
                                <div className='booking-basket-discount-wrap'>
                                    <h4 className='booking-basket-discount-label price'>{`£${orderOverview.additionalDiscountAmount?.toFixed(2)}`}</h4>
                                    <h4 className='booking-basket-discount-label'>additional discount</h4>
                                </div>
                            )}

                            <div className='booking-basket-total-wrap'>
                                <h4 className='booking-basket-total-price'>{`£${orderOverview?.amountToPay?.toFixed(2)}`}</h4>
                                <h4 className='booking-basket-total-title'>Total</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="checkout-payment-intent-wrap">
                    {
                        (!checkoutDetailsRef.current?.isGuestUser || guestUserSubmitted) ?
                            <form id="payment-form">
                                <div id="payment-element">
                                    {/* Stripe.js injects the Payment Element */}
                                </div>

                                <div className='checkout-payment-tcs-container'>
                                    <div className='checkout-payment-tcs-wrapper'>
                                        <div className='checkout-payment-tcs-checkbox-wrapper'>
                                            <label htmlFor='tAndCs' onClick={() => { setTAndCs(!tAndCs); if (!isTAndCsTouched) setIsTAndCsTouched(true); }}>
                                                {tAndCs ?
                                                    <svg className='checkout-payment-tcs-checkbox-icon' width="34" height="34" viewBox="0 0 162 162" fill="none">
                                                        <g id="Group">
                                                            <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                                            <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                                        </g>
                                                    </svg> :
                                                    <svg className='checkout-payment-tcs-checkbox-icon' width="34" height="34" viewBox="0 0 34 34" fill="none">
                                                        <g>
                                                            <rect width="34" height="34" transform="translate(0 0)" fill="black" />
                                                            <g id="Group 131">
                                                                <path id="Vector" d="M27.7592 0H6.25409L0 6.25409V27.7592L6.25409 34H27.7592L34 27.7592V6.25409L27.7592 0ZM8.34762 28.9119L5.08808 25.6524V8.34762L8.34762 5.08808H25.6391L28.8987 8.34762V25.6391L25.6391 28.8987H8.34762V28.9119Z" fill="#ffffff" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                }
                                            </label>
                                            <input className='checkout-payment-tcs-checkbox-input' type='checkbox' name='tAndCs' id='tAndCs' />
                                        </div>
                                        <h4 className='checkout-payment-tcs-label'>i’ve read and agree with the <a className='checkout-payment-tcs-link' href='/terms-and-conditions' target='_blank'>TERMS & CONDITIONS</a></h4>
                                    </div>
                                    {
                                        !tAndCs && isTAndCsTouched && (<h4 className='checkout-payment-err-msg'>
                                            Please read and agree the Privacy Policy to proceed.
                                        </h4>)
                                    }
                                </div>

                                <button className="pay-button" id="submit" type="button" onClick={handleOnCheckoutSubmit}>
                                    <div className="spinner hidden" id="spinner"></div>
                                    {/* <div class="lds-ring"><div></div><div></div><div></div><div></div></div> */}
                                    <span id="button-text">{`Pay £${orderOverview?.amountToPay?.toFixed(2)}`}</span>
                                </button>
                                <div id="payment-message" className="hidden"></div>
                            </form>
                            :
                            <>
                                <GuestRegistration orderId={checkoutDetailsRef.current.orderId} setGuestUserSubmitted={setGuestUserSubmitted}></GuestRegistration>
                            </>
                    }
                </div>

            </div>

        </div>
    )
}

export default Checkout;