import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


import './OrderSuccess.component.scss';
import pitchImg from '../../assets/images/book-a-pitch-m.png';
import musicStudioImg from '../../assets/images/book-a-studio-m.png';
import gamesRoomImg from '../../assets/images/games-room-m.png';
import infoBox from '../../assets/images/info-box.svg';

import AppContext from '../../context/app/app.context';
import { getConfirmedOrderDetails } from '../../services/order.service';
import { bookingSlotDate } from '../../services/utils.service';


const OrderSuccess = () => {

    const { appConfig, openModal } = useContext(AppContext);
    const location = useLocation();

    const orderItemDisplayConfig = {
        FULL: { img: pitchImg, name: 'Full Pitch' },
        HALF: { img: pitchImg, name: 'Half Pitch' },
        THIRD: { img: pitchImg, name: 'Third Pitch' },
        MUSIC_STUDIO: { img: musicStudioImg, name: 'Music Studio' },
        GAMES_ROOM: { img: gamesRoomImg, name: 'Games Room' }
    };
    const [orderDetail, setOrderDetail] = useState();
    const [intervalCount, setIntervalCount] = useState(0);

    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
        const searchParams = new URLSearchParams(location.search);
        const paymentIntent = searchParams.get('payment_intent');

        if (!paymentIntent) return;
        let intervalObj = setInterval(() => {
            if(intervalCount > 5){
                clearInterval(intervalObj);
                return;
            }
            getConfirmedOrderDetails(paymentIntent).then((res) => {
                if (res.data.code === "OK") {
                    setOrderDetail({ ...res.data.data });
                    clearInterval(intervalObj);
                }
            }).catch((err) => {
                console.log('Error while getting confirmed order details', err);
            });
            
            setIntervalCount(intervalCount + 1);
        }, 1000);
    }, [location.search]);

    const onContactUs = () => {
        openModal('contact');
    }


    return (
        <div className="order-success-container">

            <div className='order-success-head'>
                <div className='order-success-head-wrapper'>
                    <div className='order-success-head-item-icon-title-wrapper'>
                        {!!orderDetail?.orderItems?.length ?
                            <>
                                <svg className='order-success-head-item-icon' width="34" height="34" viewBox="0 0 162 162" fill="none">
                                    <g id="Group">
                                        <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                        <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                    </g>
                                </svg>
                                <h4 className='order-success-head-title'>order confirmed!</h4>
                            </> :
                            <>
                                { intervalCount > 5 ?
                                    <>
                                    <svg className='order-success-head-item-icon' width="78" height="78" viewBox="0 0 78 78" fill="none">
                                        <path d="M63.7354 0H14.2949L0 14.2949V63.7354L14.2949 78H63.7354L78 63.7354V14.2949L63.7354 0Z" fill="#DAFF00" />
                                        <path d="M24.842 61V57.16L34.802 40.12L25.202 23.74V19.9H32.882L39.482 32.08H39.722L46.322 19.9H53.762V23.74L44.162 40.12L54.182 57.16V61H46.502L39.542 48.16H39.362L32.282 61H24.842Z" fill="#282727" />
                                    </svg>
                                    <h4 className='order-success-head-title'>order not found!</h4>
                                    </> : <> </>
                                }
                            </>
                        }
                    </div>
                    {!!orderDetail?.orderItems?.length && <h4 className='order-success-head-description'>Thanks for booking. We look forward to seeing you.</h4>}
                </div>
            </div>
            <div className='order-success-detail-container'>

                <div className='hashtag-merky-fc-logo-with-ball'></div>
                {
                    !!orderDetail?.orderItems?.length &&
                        <div className='order-success-detail-wrap'>
                            <div className='order-success-order-details'>
                                <h1 className='order-success-order-title'>order details</h1>

                                <div className='order-success-items-wrap'>
                                    {orderDetail?.orderItems?.map((orderItem, i) => {
                                        return (
                                            <div className='order-success-item-wrap' key={i}>
                                                <img className='order-success-item-img' src={orderItemDisplayConfig[orderItem.assetType].img} alt={orderItem.assetType?.toLowerCase()?.replace('_', ' ')} draggable='false' />
                                                <div className='order-success-item-details'>
                                                    <p className='order-success-item'>{`${bookingSlotDate(orderItem.date)} //`}</p>
                                                    <p className='order-success-item'>{`${orderItem.startTime}-${orderItem.endTime} //`}</p>
                                                    <p className='order-success-item'>{`${orderItemDisplayConfig[orderItem.assetType].name} //`}</p>
                                                    {orderItem.assetType === "MUSIC_STUDIO" && <p className='order-success-item'>{`${orderItem?.peoples} People //`}</p>}
                                                    {orderItem.assetType === "GAMES_ROOM" && <div className='order-success-item'>{`£${orderItem?.unitPrice} X ${orderItem?.consoles}(Console${orderItem?.consoles > 1 ? 's':''}) //`}</div>}
                                                    <p className='order-success-item'>{`Order No. #${orderDetail?.orderNo}`}</p>
                                                </div>
                                                {orderItem.assetType === "GAMES_ROOM" ?
                                                    <span className='order-success-item-price'>{`£${(orderItem?.unitPrice * orderItem?.consoles)?.toFixed(2)}`}</span>
                                                    :
                                                    <span className='order-success-item-price'>{`£${(orderItem?.totalPrice)?.toFixed(2)}`}</span>
                                                }
                                            </div>
                                        );
                                    })}
                                </div>

                                {(orderDetail?.discountAmount > 0 && appConfig?.membershipApproved) && (
                                    <div className='order-success-discount-wrap'>
                                        <h4 className='order-success-discount-label'>membership discount</h4>
                                        <h4 className='order-success-discount-label price'>{`£${orderDetail.discountAmount?.toFixed(2) || '00.00'}`}</h4>
                                    </div>
                                )}

                                {(orderDetail?.additionalDiscountAmount > 0) && (
                                    <div className='order-success-discount-wrap'>
                                        <h4 className='order-success-discount-label'>additional discount</h4>
                                        <h4 className='order-success-discount-label price'>{`£${orderDetail.additionalDiscountAmount?.toFixed(2) || '00.00'}`}</h4>
                                    </div>
                                )}

                                <div className='order-success-detail-total-wrap'>
                                    <h4 className='order-success-detail-label'>Total</h4>
                                    <h4 className='order-success-detail-label price'>{`£${orderDetail?.amountToPay?.toFixed(2) || '00.00'}`}</h4>
                                </div>

                            </div>
                        </div>
                }

                <div className='order-success-help-wrapper'>
                    <img className='order-success-help-icon' src={infoBox} alt='' draggable="false" />
                    <h4 className='order-success-help-link'>need help with your order? <span className='manage-membership-link' onClick={onContactUs}>contact us</span></h4>
                </div>
            </div>

        </div>
    )
}

export default OrderSuccess;