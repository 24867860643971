import React, { useState } from "react";
import AppContext from "./app.context";

const AppContextProvider = (props) => {

    const [appConfig, setAppConfig] = useState({
        publicUrl: '',
        membershipPaid: false,
        membershipApproved: false,
        // membership: null,
        // subscriptionV2: null,
        bookingDaysLimit: 0,
        STRIPE_PUBLISH_KEY: ''
    });
    const [popupModalMenu, setPopupModalMenu] = useState([
        { name: 'contact', isShow: false },
        { name: 'forgottenPassword', isShow: false },
        { name: 'membershipCancel', isShow: false },
        { name: 'membershipResume', isShow: false },
        { name: 'venueEnquire', isShow: false }
    ]);

    const [modalState, setModalState] = useState();
    /**
     * @param {*} name which is added in popupModalMenu.
     * @param {*} localState you can pass data to the modal which one you open, and access via modalState from AppContext
     */
    const openModal = (name, localState = null) => {
        const modalIndex = popupModalMenu.findIndex((item) => item.name === name);
        if (modalIndex > -1) {
            if (localState) {
                setModalState(localState);
            }
            popupModalMenu[modalIndex].isShow = true;
            setPopupModalMenu([...popupModalMenu]);
        }
    };

    /** managing modal component to hide and release overflow for body */
    const onModalsClose = () => {
        for (let i = 0; i < popupModalMenu.length; i++) {
            popupModalMenu[i].isShow = false;
        }
        // document.body.style.overflow = 'auto';
        setPopupModalMenu([...popupModalMenu]);
        if (modalState) setModalState(null);
    }

    return (
        <AppContext.Provider value={{
            popupModalMenu, setPopupModalMenu,
            appConfig, setAppConfig,
            openModal: openModal,
            onModalsClose: onModalsClose,
            modalState, setModalState
        }}>
            {props.children}
        </AppContext.Provider>
    );
}

export default AppContextProvider;