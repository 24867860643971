import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import ReCAPTCHA from 'react-google-recaptcha';

import './MembershipNew.component.scss';

import AppContext from '../../context/app/app.context';
import { createMembershipDetails } from '../../services/membership.service';
import { getCurrentUser } from '../../services/user.service';
import Regexp from '../../constants/regexp';


const MembershipNew = () => {

    const { appConfig } = useContext(AppContext);
    const navigate = useNavigate();

    const _membershipTypes = [
        'Businesses', 'Clubs', 'Charter Standard/Club Mark Clubs',
        'School/Charities/Not for profits etc',
        'Over 21 and employed', 'Unemployed/Students',
    ];

    const membershipFormDefaultValues = {
        name: '',
        organization: '',
        address: '',
        postCode: '',
        contactNumber: '',
        email: '',
        dateOfBirth: '',
        typeOfMembershipRequired: '',
        proofOfEligibility: '',
        tAndCs: false
    };
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const { register, handleSubmit, formState: { errors, isDirty, isValid, touchedFields }, getValues, reset } = useForm({
        mode: 'all',
        defaultValues: membershipFormDefaultValues
    });
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const reCaptchaRef = useRef(null);


    // strip vars
    const elements = useRef();
    const stripe = useRef();
    const membershipResponse = useRef();
    const [isTAndCsTouched, setIsTAndCsTouched] = useState(false);
    const [tAndCs, setTAndCs] = useState(false);

    // Fetches a payment intent and captures the client secret
    async function initializeStripe() {
        const { clientSecret } = membershipResponse.current;

        if (!clientSecret) { return; }

        const appearance = {
            theme: 'stripe',
            variables: {
                colorPrimary: '#daff00',
                colorBackground: '#1e1e1e',
                colorText: '#ffffff',
            },
            // labels: 'floating'
        };
        elements.current = stripe.current.elements({ appearance, clientSecret });

        const paymentElementOptions = {
            layout: "tabs",
        };

        const paymentElement = elements.current.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
    };

    // Fetches the payment intent status after payment submission
    async function checkPaymentStatus() {
        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) return;

        const { paymentIntent } = await stripe.current.retrievePaymentIntent(clientSecret);

        switch (paymentIntent.status) {
            case "succeeded":
                showMessage("Payment succeeded!");
                break;
            case "processing":
                showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                showMessage("Your payment was not successful, please try again.");
                break;
            default:
                showMessage("Something went wrong.");
                break;
        }
    }

    // ------- UI helpers -------
    function showMessage(messageText) {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        // setTimeout(function () {
        //     messageContainer.classList.add("hidden");
        //     messageContainer.textContent = "";
        // }, 10000);
    }
    function hideMessage() {
        const messageContainer = document.querySelector("#payment-message");
        if (!messageContainer.classList?.contains('hidden')) {
            messageContainer.classList.add("hidden");
            messageContainer.textContent = "";
        }
    }

    // Show a spinner on payment submission
    function setLoading(isLoading) {
        if (isLoading) {
            // Disable the button and show a spinner
            document.querySelector("#submit").disabled = true;
            document.querySelector("#spinner").classList.remove("hidden");
            document.querySelector("#button-text").classList.add("hidden");
        } else {
            document.querySelector("#submit").disabled = false;
            document.querySelector("#spinner").classList.add("hidden");
            document.querySelector("#button-text").classList.remove("hidden");
        }
    }

    async function handleOnCheckoutSubmit(e) {
        e.preventDefault();
        window.hideBeforeunloadWarning = true;
        setLoading(true);
        hideMessage();

        setTimeout(async () => {
            if (!isTAndCsTouched) { setIsTAndCsTouched(true); }

            if (!tAndCs || !elements.current) {
                setLoading(false);
                return;
            }

            const { error } = await stripe.current.confirmPayment({
                elements: elements.current,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    // return_url: `${appConfig?.clientUrl}/order-success`,
                    return_url: `${appConfig?.clientUrl}/membership-purchase-response`,
                    receipt_email: getCurrentUser()?.email || null,
                },
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "validation_error") {
                showMessage(error.message);
            } else if (error.type === "card_error") {
                navigate('/membership-purchase-response', { state: { isRedirectViaMembership: true } });
            } else {
                showMessage("An unexpected error occurred.");
            }
            setLoading(false);
            window.hideBeforeunloadWarning = false;
        }, 100);
    }

    /** component will-mount and unmount*/
    useEffect(() => {
        window.scrollTo(0, 0);
        /** when we receive hideBeforeunloadWarning in true state on component did-mount it means payment is DONE */
        window.hideBeforeunloadWarning = false;
        const unloadCallback = (event) => {
            if (!window.hideBeforeunloadWarning) {
                event.preventDefault();
                event.returnValue = ""; // Gecko + IE
                return "";              // Webkit, Safari, Chrome etc.
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const [membershipFormSubmitted, setMembershipFormSubmitted] = useState(false);
    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                setIsRequestAlive(true);
                const { tAndCs, ...body } = data;
                const token = reCaptchaRef.current.getValue();
                reCaptchaRef.current.reset();
                if (token) {
                    body.reCaptchaToken = token;
                }

                createMembershipDetails(body).then((res) => {
                    setIsRequestAlive(false);
                    if (res.data.code === 'CREATED') {
                        window.scrollTo(0, 0);
                        reset(membershipFormDefaultValues);
                        setMembershipFormSubmitted(true);

                        if (appConfig.STRIPE_PUBLISH_KEY) {
                            stripe.current = window.Stripe(appConfig.STRIPE_PUBLISH_KEY);
                        }
                        membershipResponse.current = res.data.data;
                        setTimeout(async () => {
                            await initializeStripe();
                            await checkPaymentStatus();
                        }, 200);
                    }
                }).catch((err) => {
                    setIsRequestAlive(false);
                    console.error('Error while submitting form', err);
                });
            }
        } catch (err) {
            setIsRequestAlive(false);
            console.error(`Error while submitting form`, err);
        }
    }

    return (
        <div className="new-membership-container">
            <div className='membership-wrap'>
                <div className='details-container'>
                    <div className='details-wrap'>
                        <h2 className='detail-title'>welcome to selhurst sports arena membership</h2>

                        <ul className='points-wrap style-none'>
                            <li className='point'>
                                Want free entry to events organized by Selhurst Sports Arena and priority booking of the facilities and not to talk of at least 18% of booking fees? Then become a Member today.
                            </li>
                        </ul>
                    </div>

                    <div className='details-wrap'>
                        <h2 className='detail-title'>experience more</h2>

                        <ul className='points-wrap'>
                            <li className='point'>Chance to have a say on the events organized at the Arena</li>
                            <li className='point'>Invitations to special Members' events</li>
                            <li className='point'>Exclusive Members offers</li>
                        </ul>
                    </div>

                    <div className='details-wrap'>
                        <h2 className='detail-title'>access more</h2>

                        <ul className='points-wrap'>
                            <li className='point'>Free entry to all Sports Events organized by Selhurst Sports Arena</li>
                            <li className='point'>Priority booking</li>
                            <li className='point'>Be the first to hear about events</li>
                        </ul>
                    </div>

                    <div className='details-wrap'>
                        <h2 className='detail-title'>save more</h2>

                        <ul className='points-wrap'>
                            <li className='point'>At least 18% savings on booking fees compared to rates paid by non members</li>
                        </ul>
                    </div>

                    <div className='details-wrap'>
                        <h2 className='detail-title'>join now</h2>

                        <ul className='points-wrap'>
                            <li className='point'>STEP 1 - Complete the membership form below and press 'SUBMIT' Button for the next screen</li>
                            <li className='point'>STEP 2 - Select the required membership option(s) to your basket and complete purchase. We will contact you soon after to confirm your membership or adjust invoice if incorrect category selected.</li>
                            <li className='point'>STEP 3 - Lastly we would ask you to please download and complete the voluntary Equal Opportunities Questionnaire and email to info@selhurstsportsarena.org. The form is to help us ensure Our Venue is accessible and used by all of our community.</li>
                        </ul>
                    </div>
                </div>

                {/* membership form is submitted */}
                {
                    (membershipFormSubmitted && membershipResponse.current) ? <>
                        <div className="checkout-payment-intent-wrap">
                            <form id="payment-form">
                                <h2 className='form-title'>step 2</h2>
                                <div id="payment-element">
                                    {/* Stripe.js injects the Payment Element */}
                                </div>

                                <div className='checkout-payment-tcs-container'>
                                    <div className='checkout-payment-tcs-wrapper'>
                                        <div className='checkout-payment-tcs-checkbox-wrapper'>
                                            <label htmlFor='tAndCs' onClick={() => { setTAndCs(!tAndCs); if (!isTAndCsTouched) setIsTAndCsTouched(true); }}>
                                                {tAndCs ?
                                                    <svg className='checkout-payment-tcs-checkbox-icon' width="34" height="34" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="Group">
                                                            <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                                            <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                                        </g>
                                                    </svg> :
                                                    <svg className='checkout-payment-tcs-checkbox-icon' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g>
                                                            <rect width="34" height="34" transform="translate(0 0)" fill="black" />
                                                            <g id="Group 131">
                                                                <path id="Vector" d="M27.7592 0H6.25409L0 6.25409V27.7592L6.25409 34H27.7592L34 27.7592V6.25409L27.7592 0ZM8.34762 28.9119L5.08808 25.6524V8.34762L8.34762 5.08808H25.6391L28.8987 8.34762V25.6391L25.6391 28.8987H8.34762V28.9119Z" fill="#ffffff" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                }
                                            </label>
                                            <input className='checkout-payment-tcs-checkbox-input' type='checkbox' name='tAndCs' id='tAndCs' />
                                        </div>
                                        <h4 className='checkout-payment-tcs-label'>i’ve read and agree with the <a className='checkout-payment-tcs-link' href='/terms-and-conditions' target='_blank'>TERMS & CONDITIONS</a></h4>
                                    </div>
                                    {
                                        !tAndCs && isTAndCsTouched && (<h4 className='checkout-payment-err-msg'>
                                            Please read and agree the Privacy Policy to proceed.
                                        </h4>)
                                    }
                                </div>

                                <button id="submit" type="button" onClick={handleOnCheckoutSubmit}>
                                    <div className="spinner hidden" id="spinner"></div>
                                    <span id="button-text">{`Pay £${membershipResponse.current?.amountToPay}`}</span>
                                </button>
                                <div id="payment-message" className="hidden"></div>
                            </form>
                        </div>
                    </> : <>
                        <form className='form-container' onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
                            <h2 className='form-title'>step 1</h2>
                            <div className='fields-wrap'>
                                <div>
                                    <div className='input-field-wrap'>
                                        <input className='input-field' type='text' name='name' placeholder='name*'
                                            {...register('name', {
                                                required: 'This field is required.',
                                                validate: (value) => (value?.length > 254 ? 'name must not exceed 150 characters.' : null)
                                            })} />
                                    </div>
                                    {
                                        (touchedFields.name && errors.name) && (<h4 className='input-field-err-msg'>
                                            {errors.name.message}
                                        </h4>)
                                    }
                                </div>
                                <div>
                                    <div className='input-field-wrap'>
                                        <input className='input-field' type='text' name='organization' placeholder='organization/club/school etc.'
                                            {...register('organization')} />
                                    </div>
                                </div>
                                <div>
                                    <div className='input-field-wrap'>
                                        <input className='input-field' type='text' name='address' placeholder='address*'
                                            {...register('address', {
                                                required: 'This field is required.',
                                                validate: (value) => (value?.length > 500 ? 'address must not exceed 500 characters.' : null)
                                            })} />
                                    </div>
                                    {
                                        (touchedFields.address && errors.address) && (<h4 className='input-field-err-msg'>
                                            {errors.address.message}
                                        </h4>)
                                    }
                                </div>
                                <div>
                                    <div className='input-field-wrap'>
                                        <input className='input-field' type='text' name='postCode' placeholder='post code*'
                                            {...register('postCode', { required: 'This field is required.' })} />
                                    </div>
                                    {
                                        (touchedFields.postCode && errors.postCode) && (<h4 className='input-field-err-msg'>
                                            {errors.postCode.message}
                                        </h4>)
                                    }
                                </div>
                                <div>
                                    <div className='input-field-wrap'>
                                        <input className='input-field' type='text' name='contactNumber' placeholder='contact number'
                                            {...register('contactNumber', {
                                                pattern: {
                                                    value: Regexp._MOBILE_NUMBER,
                                                    message: 'Please enter valid contact number.'
                                                }
                                            })} />
                                    </div>
                                    {
                                        (touchedFields.contactNumber && errors.contactNumber) && (<h4 className='input-field-err-msg'>
                                            {errors.contactNumber.message}
                                        </h4>)
                                    }
                                </div>
                                <div>
                                    <div className='input-field-wrap'>
                                        <input className='input-field' type='text' name='email' placeholder='email*'
                                            {...register('email', {
                                                required: 'This field is required.',
                                                pattern: {
                                                    value: Regexp._EMAIL,
                                                    message: 'Please enter valid e-mail address.'
                                                },
                                                validate: (value) => (value?.length > 254 ? 'An email address must not exceed 254 characters.' : null)
                                            })} />
                                    </div>
                                    {
                                        (touchedFields.email && errors.email) && (<h4 className='input-field-err-msg'>
                                            {errors.email.message}
                                        </h4>)
                                    }
                                </div>
                                <div>
                                    <div className='input-field-wrap'>
                                        <input className={'input-field date' + (getValues('dateOfBirth') ? '' : ' placeholder')} name='dateOfBirth'
                                                placeholder="DD/MM/YYYY" type='date' defaultValue={null}
                                            {...register('dateOfBirth', {
                                                required: 'This field is required.'
                                            })} />
                                    </div>
                                    {
                                        (touchedFields.dateOfBirth && errors.dateOfBirth) && (<h4 className='input-field-err-msg'>
                                            {errors.dateOfBirth.message}
                                        </h4>)
                                    }
                                </div>
                                <div>
                                    <div className='input-field-wrap'>
                                        <select className={'input-field select-input' + (getValues('typeOfMembershipRequired') ? '' : ' placeholder')} name='typeOfMembershipRequired'
                                            {...register('typeOfMembershipRequired', { required: 'This field is required.' })}
                                            placeholder='type of membership required' defaultValue=''>
                                            <option value='' disabled>type of membership required</option>
                                            {_membershipTypes.map((membershipType, i) => {
                                                return <option key={i}>{membershipType}</option>
                                            })}
                                        </select>
                                        {/* <input className='input-field' type='text' name='typeOfMembershipRequired' placeholder='type of membership required'
                                            {...register('typeOfMembershipRequired')} /> */}
                                    </div>
                                    {
                                        (touchedFields.email && errors.email) && (<h4 className='input-field-err-msg'>
                                            {errors.email.message}
                                        </h4>)
                                    }
                                </div>
                                <div>
                                    <div className='input-field-wrap'>
                                        <input className='input-field' type='text' name='proofOfEligibility' placeholder='proof of eligibility*'
                                            {...register('proofOfEligibility', { required: 'This field is required.' })} />
                                    </div>
                                    {
                                        (touchedFields.typeOfMembershipRequired && errors.typeOfMembershipRequired) && (<h4 className='input-field-err-msg'>
                                            {errors.typeOfMembershipRequired.message}
                                        </h4>)
                                    }
                                </div>
                            </div>

                            <p className='input-field-note'>*denotes a required fields</p>

                            <div className='tcs-container'>
                                <div className='tcs-wrap'>
                                    <div className='tcs-checkbox-wrap'>
                                        <label htmlFor='tAndCs'>
                                            {getValues('tAndCs') ?
                                                <svg className='tcs-checkbox-icon' width="34" height="34" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Group">
                                                        <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                                        <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                                    </g>
                                                </svg> :
                                                <svg className='tcs-checkbox-icon' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g>
                                                        <rect width="34" height="34" transform="translate(0 0)" fill="black" />
                                                        <g id="Group 131">
                                                            <path id="Vector" d="M27.7592 0H6.25409L0 6.25409V27.7592L6.25409 34H27.7592L34 27.7592V6.25409L27.7592 0ZM8.34762 28.9119L5.08808 25.6524V8.34762L8.34762 5.08808H25.6391L28.8987 8.34762V25.6391L25.6391 28.8987H8.34762V28.9119Z" fill="#ffffff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            }
                                        </label>
                                        <input className='tcs-checkbox-input' type='checkbox' {...register('tAndCs', { required: 'Please read and agree the Privacy Policy to proceed.' })} name='tAndCs' id='tAndCs' />
                                    </div>
                                    <h4 className='tcs-label'>i’ve read and agree with the <a className='tcs-link' href='/terms-and-conditions' target='_blank'>TERMS & CONDITIONS</a></h4>
                                </div>
                                {
                                    errors.tAndCs && (<h4 className='input-field-err-msg'>
                                        {errors.tAndCs.message}
                                    </h4>)
                                }
                            </div>

                            <div className='re-captcha-wrap'>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    ref={reCaptchaRef}
                                    onChange={(value) => setIsCaptchaVerified(!!value)}
                                />
                            </div>

                            <div className='action-wrapper'>
                                <div className='action-btn-wrap'>
                                    <button className={'action-btn' + ((!isCaptchaVerified || isRequestAlive || Object.keys(errors).length) ? ' in-valid' : '')} type='submit' disabled={!isCaptchaVerified || isRequestAlive || Object.keys(errors).length}>
                                        submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </div>
        </div>
    )
}

export default MembershipNew;