import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import './AccountSignUpIn.component.scss';


function AccountSignUpIn() {

    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="account-sign-up-in-container">

            <div className='account-sign-up-in-wrapper'>
                <NavLink className='account-sign-up-in-item' to="/account-sign-up">
                    Sign up
                </NavLink>

                <NavLink className='account-sign-up-in-item' to="/account-sign-in">
                    login
                </NavLink>
            </div>
        </div>
    )
}

export default AccountSignUpIn;