export const _pitchTypes = [
    { a: 'fullPitch', d: 'Full Pitch' },
    { a: 'halfPitch', d: 'Half Pitch' },
    { a: 'thirdPitch', d: 'Third Pitch' }
];

export const _studioTypes = [
    { a: 'x6Capacity', d: 'X6 Capacity' },
    { a: 'x4Capacity', d: 'X4 Capacity' },
    { a: 'x2Capacity', d: 'X2 Capacity' }
];

export const _monthsNames = {
    short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    long: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]
};

export const _dayNames = {
    short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    long: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    getShortDayName: function (i) {
        return (i < this.short.length) ? this.short[i]?.toLocaleLowerCase() : '';
    }
};


export const _slots = [
    {
        date: 'Thu 13 sep',
        list: [
            { time: '10:00-11:00', price: '£11.00', type: 'full pitch', isBooked: false }, //Sunday 
            { time: '11:00-12:00', price: '£12.00', type: 'half pitch', isBooked: true }, //Monday 
            { time: '12:00-13:00', price: '£13.00', type: 'half pitch', isBooked: false }, //Tuesday 
            { time: '13:00-14:00', price: '£14.00', type: 'full pitch', isBooked: true }, //Wednesday 
            { time: '14:00-15:00', price: '£15.00', type: 'third pitch', isBooked: false }] //Thursday 
    },
    {
        date: 'Thu 14 sep',
        list: [
            { time: '10:00-11:00', price: '£11.00', type: 'full pitch', isBooked: false }, //Sunday 
            { time: '11:00-12:00', price: '£12.00', type: 'half pitch', isBooked: false }, //Monday 
            { time: '12:00-13:00', price: '£13.00', type: 'full pitch', isBooked: true }, //Tuesday 
            { time: '13:00-14:00', price: '£14.00', type: 'third pitch', isBooked: false }, //Wednesday 
            { time: '14:00-15:00', price: '£15.00', type: 'full pitch', isBooked: true }, //Thursday 
            { time: '15:00-16:00', price: '£11.00', type: 'third pitch', isBooked: false }, //Friday 
            { time: '16:00-17:00', price: '£12.00', type: 'full pitch', isBooked: false } //Saturday 
        ]
    },
    {
        date: 'Thu 15 sep',
        list: [
            { time: '18:00-19:00', price: '£13.00', type: 'half pitch', isBooked: true }, //Sunday 
            { time: '19:00-20:00', price: '£14.00', type: 'third pitch', isBooked: false }, //Monday 
            { time: '20:00-21:00', price: '£15.00', type: 'full pitch', isBooked: false }, //Tuesday 
        ]
    },
    {
        date: 'Thu 16 sep',
        list: [
            { time: '10:00-11:00', price: '£11.00', type: 'full pitch', isBooked: false }, //Sunday 
            { time: '11:00-12:00', price: '£12.00', type: 'half pitch', isBooked: true }, //Monday 
            { time: '12:00-13:00', price: '£13.00', type: 'half pitch', isBooked: false }, //Tuesday 
            { time: '13:00-14:00', price: '£14.00', type: 'full pitch', isBooked: true }, //Wednesday 
            { time: '14:00-15:00', price: '£15.00', type: 'third pitch', isBooked: false }] //Thursday 
    },
    {
        date: 'Thu 17 sep',
        list: [
            { time: '10:00-11:00', price: '£11.00', type: 'full pitch', isBooked: false }, //Sunday 
            { time: '11:00-12:00', price: '£12.00', type: 'half pitch', isBooked: false }, //Monday 
            { time: '12:00-13:00', price: '£13.00', type: 'full pitch', isBooked: true }, //Tuesday 
            { time: '13:00-14:00', price: '£14.00', type: 'third pitch', isBooked: false }, //Wednesday 
            { time: '14:00-15:00', price: '£15.00', type: 'full pitch', isBooked: true }, //Thursday 
            { time: '15:00-16:00', price: '£11.00', type: 'third pitch', isBooked: false }, //Friday 
            { time: '16:00-17:00', price: '£12.00', type: 'full pitch', isBooked: false } //Saturday 
        ]
    },
    {
        date: 'Thu 18 sep',
        list: [
            { time: '18:00-19:00', price: '£13.00', type: 'half pitch', isBooked: true }, //Sunday 
            { time: '19:00-20:00', price: '£14.00', type: 'third pitch', isBooked: false }, //Monday 
            { time: '20:00-21:00', price: '£15.00', type: 'full pitch', isBooked: false }, //Tuesday 
        ]
    },
    {
        date: 'Thu 19 sep',
        list: [
            { time: '10:00-11:00', price: '£11.00', type: 'full pitch', isBooked: false }, //Sunday 
            { time: '11:00-12:00', price: '£12.00', type: 'half pitch', isBooked: false }, //Monday
        ]
    },
    {
        date: 'Thu 20 sep',
        list: [
            { time: '18:00-19:00', price: '£13.00', type: 'half pitch', isBooked: true }, //Sunday 
            { time: '19:00-20:00', price: '£14.00', type: 'third pitch', isBooked: false }, //Monday 
            { time: '20:00-21:00', price: '£15.00', type: 'full pitch', isBooked: false }, //Tuesday 
        ]
    },
    {
        date: 'Thu 21 sep',
        list: [
            { time: '10:00-11:00', price: '£11.00', type: 'full pitch', isBooked: false }, //Sunday 
            { time: '11:00-12:00', price: '£12.00', type: 'half pitch', isBooked: true }, //Monday
        ]
    },
];

export const getSlotList = (start, end) => {

    if ((_slots.length) >= end) {
        const dummy = _slots.slice(start, end);
        return dummy;
    }
    return null;
}

// old one = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
export const _mobileNumberRegex = new RegExp('^\\+?(?:\\d\\s?){10,12}$', 'g');