import React from 'react';
import { NavLink } from "react-router-dom";

import './Footer.component.scss';


const Footer = () => {

    return (
        <footer className="footer-wrapper">

            <div className="navigation-wrapper">
                <NavLink
                    end
                    to="/"
                    className={({ isActive }) => isActive ? 'nav-link nav-link_active' : 'nav-link'}
                >
                    © merky fc. all rights reserved.
                </NavLink>
                <div className='vertical-divider'></div>
                <NavLink
                    to="/privacy-policy"
                    className={({ isActive }) => isActive ? 'nav-link nav-link_active' : 'nav-link'}
                >
                    privacy policy
                </NavLink>
                <div className='vertical-divider'></div>
                <NavLink
                    to="/terms-and-conditions"
                    className={({ isActive }) => isActive ? 'nav-link nav-link_active' : 'nav-link'}
                >
                    t&cs
                </NavLink>
            </div>

            <div className="footer-pattern"></div>

        </footer>
    )
}

export default Footer;