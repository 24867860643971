import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './ResetPassword.component.scss';

import { resetPassword } from '../../services/user.service';
import Regexp from '../../constants/regexp';


function ResetPassword() {

    const navigate = useNavigate();
    const params = useParams();

    const [encodeKey, setEncodeKey] = useState();
    const registerDefaultValues = {
        password: '',
        reEnterPassword: '',
    };
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const { register, handleSubmit, formState: { errors, isDirty, isValid, touchedFields }, getValues, watch, setError, clearErrors } = useForm({
        mode: 'all',
        defaultValues: registerDefaultValues
    });
    const [errorMsg, setErrorMsg] = useState('');

    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
        if (!params?.token?.trim()) {
            navigate('/account-sign-in');
        }
        setEncodeKey(`${window.screen.height}${window.screen.width}${window.screen.colorDepth}${new Date().getTime()}`);
        // eslint-disable-next-line
    }, []);


    const passwordWatch = watch("password", "");
    useEffect(() => {
        if (touchedFields.reEnterPassword && getValues('password') !== getValues('reEnterPassword')) {
            if (getValues('reEnterPassword')) {
                setError('reEnterPassword', { type: 'validate', message: 'Password does not match.' });
            } else {
                setError('reEnterPassword', { type: 'required', message: 'This field is required.' });
            }
        } else if (errors.reEnterPassword && errors.reEnterPassword.type !== 'required') {
            clearErrors('reEnterPassword');
        }
        // eslint-disable-next-line
    }, [passwordWatch]);

    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                setIsRequestAlive(true);
                const { reEnterPassword, ...resetBody } = data;
                resetBody.token = params.token;

                resetPassword(resetBody, encodeKey).then((res) => {
                    if (res.data.code === 'OK') {
                        navigate('/account-sign-in');
                    }
                    setIsRequestAlive(false);
                }).catch((err) => {
                    setErrorMsg(err.response.data.message);
                    setIsRequestAlive(false);
                    console.log('Error while reset password', err);
                });
            }
        } catch (error) {
            setIsRequestAlive(false);
            console.log('Error while reset password', error);
        }
    }


    return (
        <div className="reset-pass-container">

            <div className='reset-pass-wrapper'>
                <form onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
                    <div className='reset-pass-form'>
                        <h4 className='reset-pass-form-title'>reset your password</h4>
                        
                        <div className='reset-pass-form-body'>
                            <div>
                                <div className='reset-pass-form-body-item'>
                                    <input className='reset-pass-form-body-item-input' type='password' name='password' placeholder='password'
                                        {...register('password', {
                                            required: 'This field is required.',
                                            pattern: {
                                                value: Regexp._PASSWORD,
                                                message: 'Password pattern does not match, please check it.'
                                            }
                                        })} />
                                </div>
                                {
                                    (touchedFields.password && errors.password) && (<h4 className='reset-pass-form-body-item-err-msg'>
                                        {errors.password.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='reset-pass-form-body-item'>
                                    <input className='reset-pass-form-body-item-input' type='password' name='reEnterPassword' placeholder='re-enter new password'
                                        onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                        }}
                                        {...register('reEnterPassword', {
                                            required: 'This field is required.',
                                            validate: (value) => value === passwordWatch || 'Password does not match.'
                                        })} />
                                </div>
                                {
                                    (touchedFields.reEnterPassword && errors.reEnterPassword) && (<h4 className='reset-pass-form-err-msg'>
                                        {errors.reEnterPassword.message}
                                    </h4>)
                                }
                            </div>
                        </div>
                        {errorMsg && <h4 className='reset-pass-form-err-msg'>{errorMsg}</h4>}
                        <h4 className='reset-pass-form-note'>* Password must include 8 characters, uppercase, lowercase letters and one number</h4>
                    </div>

                    <div className='reset-pass-action-wrapper'>
                        <div className='reset-pass-action'>
                            <button className={'reset-pass-action-btn' + ((isRequestAlive || Object.keys(errors).length) ? ' in-valid' : '')} type='submit' disabled={isRequestAlive || Object.keys(errors).length}>
                                reset password
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;