import axios from './axios';
import { encodeData } from './utils.service';

// const baseApiUrl = process.env.REACT_APP_API_URL;

export const getProjectSync = (body, encodeKey) => {
    body = {
        data: encodeData(body, encodeKey),
        date: encodeKey
    };
    return axios.post(`/sync`, body);
}
