import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import './ForgottenPassword.component.scss';

import AppContext from '../../context/app/app.context';
import { getResetPasswordLinkByEmail } from '../../services/user.service';
import Regexp from '../../constants/regexp';


const ForgottenPassword = () => {

    const { onModalsClose } = useContext(AppContext);
    const navigate = useNavigate();
    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
        mode: 'all',
        defaultValues: { email: '' }
    });
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isEmailSend, setIsEmailSend] = useState(false);

    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                setIsRequestAlive(true);
                const body = data;
                getResetPasswordLinkByEmail(body).then((res) => {
                    if (res.data.code === 'OK') {
                        console.log(res)
                        setIsEmailSend(true);
                    }
                    setIsRequestAlive(false);
                }).catch((err) => {
                    setErrorMsg(err.response.data.message);
                    setIsRequestAlive(false);
                    console.log('Error while forgot password', err);
                });
            }
        } catch (error) {
            setIsRequestAlive(false);
            console.log('Error while forgot password', error);
        }
    }

    const onCloseModal = () => {
        onModalsClose();
    }

    const onBackToLogin = () => {
        onModalsClose();
        // it's like navigate('/account-sign-in');
        navigate(0);
        return;
    }

    return (
        <div className="forgot-pass-container">
            {!isEmailSend ?
                <div className='forgot-pass-wrapper'>
                    <div className='forgot-pass-modal-close-btn' onClick={onCloseModal}>
                        <svg viewBox="0 0 32 32" fill="none">
                            <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#1E1E1E" />
                        </svg>
                    </div>

                    <div className='forgot-pass-poster-wrapper'>
                        <img className='forgot-pass-poster' draggable="false" alt='' />
                    </div>
                    <div className='forgot-pass-form-wrapper'>
                        <form className='forgot-pass-form' onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
                            <div>
                                <h4 className='forgot-pass-form-title'>please enter your email address</h4>
                                <p className='forgot-pass-form-description'>Use the box below, then press submit.</p>
                            </div>

                            <div className='forgot-pass-form-body'>
                                <div className='forgot-pass-form-item'>
                                    <input className='forgot-pass-form-item-input' type='text' name='email' placeholder='email address'
                                        {...register('email', {
                                            required: 'This field is required.',
                                            pattern: {
                                                value: Regexp._EMAIL,
                                                message: 'Please enter a valid e-mail address.'
                                            }
                                        })} />
                                    {
                                        errors.email && (<h4 className='forgot-pass-form-err-msg'>
                                            {errors.email.message}
                                        </h4>)
                                    }
                                    {
                                        errorMsg && (<h4 className='forgot-pass-form-err-msg'>
                                            {errorMsg}
                                        </h4>)
                                    }
                                </div>

                                <div className='forgot-pass-form-action'>
                                    <button className={'forgot-pass-form-submit-btn' + ((isRequestAlive || Object.keys(errors).length) ? ' in-valid' : '')} type='submit' disabled={isRequestAlive || Object.keys(errors).length}>submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                :
                <div className='forgot-pass-success-wrapper'>
                    <h4 className='forgot-pass-success-title'>please check your emails</h4>

                    <button className='forgot-pass-success-btn' type='button' onClick={onBackToLogin}>back to login</button>
                </div>
            }
        </div>
    )
}

export default ForgottenPassword;