import axios from './axios';
import { getSessionHeader } from './user.service';
// import { encodeData } from './utils.service';

// const baseApiUrl = process.env.REACT_APP_API_URL;
const endPoint = '/booking';



export const getBasketItems = () => {
    const headers = getSessionHeader() ?? {};

    return axios.get(`${endPoint}/get-basket-items`, { headers: headers });
}

export const getSlotList = (body, encodeKey) => {
    // body = {
    //     data: encodeData(body, encodeKey),
    //     date: encodeKey
    // };
    return axios.post(`${endPoint}/slots`, body);
}

export const addToBasket = (body) => {
    const headers = getSessionHeader() ?? {};

    return axios.post(`${endPoint}/add-to-basket`, body, { headers: headers });
}

export const removeToBasket = (body) => {
    const headers = getSessionHeader() ?? {};

    return axios.post(`${endPoint}/remove-to-basket`, body, { headers: headers });
}