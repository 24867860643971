import axios from './axios';
import { encodeData } from './utils.service';

// const baseApiUrl = process.env.REACT_APP_API_URL;
const endPoint = '/auth';


export const signup = (body, encodeKey) => {
    body = {
        data: encodeData(body, encodeKey),
        date: encodeKey
    };
    return axios.post(`${endPoint}/register`, body);
}

export const createGuestUser = (body) => {
    const headers = getSessionHeader() ?? {};

    return axios.post(`${endPoint}/create-guest-user`, body, { headers: headers });
}

export const login = (body, encodeKey) => {
    body = {
        data: encodeData(body, encodeKey),
        date: encodeKey
    };
    return axios.post(`${endPoint}/login`, body);
}

export const getResetPasswordLinkByEmail = (body) => {
    return axios.post(`${endPoint}/email-verification`, body);
}

export const resetPassword = (body, encodeKey) => {
    body = {
        data: encodeData(body, encodeKey),
        date: encodeKey
    };
    return axios.post(`${endPoint}/reset-password`, body);
}

export const getUserById = (id) => {
    return axios.get(`/users/${id}`);
}

export const updateUserById = (id, body, encodeKey) => {
    body = {
        data: encodeData(body, encodeKey),
        date: encodeKey
    };
    return axios.put(`/users/${id}`, body);
}

export const membership = (body) => {
    return axios.post(`/subscriber/signup`, body);
}

export const userDashboard = () => {
    return axios.get(`/dashboard/detail`);
}



export const setCurrentUserSession = (res) => {
    localStorage.clear();
    /** expiresIn seconds convert into milliseconds */
    const expiresIn = (parseInt(res.data.token.expiresIn) * 1000);
    /** token still valid upto expiresAt date-time */
    localStorage.setItem('expiresAt', (new Date().getTime() + expiresIn).toString());
    localStorage.setItem('token', res.data.token.accessToken);
    localStorage.setItem('user', JSON.stringify(res.data.user));
    /** remove session user is present before logged in */
    localStorage.removeItem('sessionId');
}

export const getSessionHeader = () => {
    if (localStorage.getItem('sessionId')) {
        return { 'Session-Id': localStorage.getItem('sessionId') };
    }
}

export const getCurrentUser = () => {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
}

export const isUserLoggedIn = () => {
    const expiresAt = localStorage.getItem('expiresAt');
    const token = localStorage.getItem('token');
    if (token && expiresAt) {
        const status = new Date().getTime() < new Date(parseInt(expiresAt)).getTime();
        if (!status) { localStorage.clear(); }
        return status;
    } else {
        return false;
    }
}

export const logout = () => {
    localStorage.clear();
    window.location.href = '/';
    return;
}