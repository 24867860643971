import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './ContactUs.component.scss';
import contactVenueMap from '../../assets/images/contact-us-venue-map.png';

import AppContext from '../../context/app/app.context';
import { onSaveContactUs } from '../../services/contact-us.service';
import Regexp from '../../constants/regexp';


const ContactUs = () => {

    const { onModalsClose } = useContext(AppContext);
    const navigate = useNavigate();
    /** managing modal component to hide and release overflow for body */
    const onModalClose = () => {
        onModalsClose();
    }

    const contactUsDefaultValues = {
        name: '',
        email: '',
        message: '',
        tAndCs: false
    };
    const { register, handleSubmit, formState: { errors, isDirty, isValid }, getValues } = useForm({
        mode: 'all',
        defaultValues: contactUsDefaultValues
    });
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const [responseErrorMsg, setResponseErrorMsg] = useState('');
    const [isReqSubmitted, setIsReqSubmitted] = useState(false);

    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                setIsRequestAlive(true);
                onSaveContactUs(data).then((res) => {
                    if (res.data.code === 'OK') {
                        /** re-set form fields and error message */
                        setResponseErrorMsg('');
                        setIsReqSubmitted(true);
                    }
                    setIsRequestAlive(false);
                }).catch((err) => {
                    setIsRequestAlive(false);
                    setResponseErrorMsg(err.response.data.message);
                    console.log('Error while submitting contact us', err);
                });
            }
        } catch (error) {
            setIsRequestAlive(false);
            console.log('Error while submitting contact us', error);
        }
    }

    const onBackToHome = () => {
        onModalsClose();
        navigate('/');
        return;
    }



    return (
        <div className="contact-us-container">

            {!isReqSubmitted &&
                <div className='contact-us-wrapper'>

                    <div className='contact-us-header'>
                        <div className='contact-us-modal-close-btn' onClick={onModalClose}>
                            <svg viewBox="0 0 32 32" fill="none">
                                <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#1E1E1E" />
                            </svg>
                        </div>
                        <h4 className='contact-us-title'>contact us</h4>
                        <p className='contact-us-description'>How can we help? Send us a message below.</p>
                    </div>

                    <form className='contact-us-form' onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
                        <div className='contact-us-form-textarea'>
                            <textarea placeholder='Insert message here...'
                                {...register('message', {
                                    required: 'This field is required.'
                                })} />
                            {
                                errors.message && (<h4 className='contact-us-form-item-input-err-msg'>
                                    {errors.message.message}
                                </h4>)
                            }
                        </div>
                        <div className='contact-us-form-item'>
                            <input className='contact-us-form-item-input-field' type='text' name='name' placeholder='name'
                                {...register('name', {
                                    required: 'This field is required.'
                                })} />
                            {
                                errors.name && (<h4 className='contact-us-form-item-input-err-msg'>
                                    {errors.name.message}
                                </h4>)
                            }
                        </div>
                        <div className='contact-us-form-item'>
                            <input className='contact-us-form-item-input-field' type='text' name='email' placeholder='email address'
                                {...register('email', {
                                    required: 'This field is required.',
                                    pattern: {
                                        value: Regexp._EMAIL,
                                        message: 'Please enter a valid e-mail address.'
                                    }
                                })} />
                            {
                                errors.email && (<h4 className='contact-us-form-item-input-err-msg'>
                                    {errors.email.message}
                                </h4>)
                            }
                        </div>

                        <div className='terms-condition-container'>
                            <div className='terms-condition-wrapper'>
                                <div className='terms-condition-checkbox'>
                                    <label htmlFor='tAndCs'>
                                        {getValues('tAndCs') ?
                                            <svg className='terms-condition-checkbox-icon' width="34" height="34" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="Group">
                                                    <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                                    <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                                </g>
                                            </svg> :
                                            <svg className='terms-condition-checkbox-icon' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M27.7592 0H6.25409L0 6.25409V27.7592L6.25409 34H27.7592L34 27.7592V6.25409L27.7592 0ZM8.34762 28.9119L5.08808 25.6524V8.34762L8.34762 5.08808H25.6391L28.8987 8.34762V25.6391L25.6391 28.8987H8.34762V28.9119Z" fill="#282727" />
                                            </svg>
                                        }
                                    </label>
                                    <input className='terms-condition-checkbox-input' type='checkbox' {...register('tAndCs', { required: 'Please read and agree the Privacy Policy to proceed.' })} name='tAndCs' id='tAndCs' />
                                </div>
                                <h4 className='contact-us-form-item-tc'>i’ve read and agree with the <a className='contact-us-form-item-tc-link' href='/privacy-policy' target='_blank'>privacy policy</a>.</h4>
                            </div>
                            {
                                errors.tAndCs && (<h4 className='contact-us-form-item-input-err-msg'>
                                    {errors.tAndCs.message}
                                </h4>)
                            }
                            {responseErrorMsg && (<h4 className='contact-us-form-item-input-err-msg'>
                                {responseErrorMsg}
                            </h4>)}
                        </div>


                        <div className='contact-us-form-action'>
                            <button className='contact-us-form-submit-btn' type='submit' disabled={isRequestAlive}>submit</button>
                        </div>
                    </form>

                    <div className='contact-us-footer'>
                        <div className='contact-us-venue-map'>
                            <img className='contact-us-venue-map-img' src={contactVenueMap} draggable='false' alt='contact us venue'></img>
                        </div>
                    </div>

                </div>
            }

            {isReqSubmitted &&
                <div className='contact-us-sbm-wrapper'>
                    <div className='contact-us-sbm-modal-close-btn' onClick={onModalClose}>
                        <svg viewBox="0 0 32 32" fill="none">
                            <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#1E1E1E" />
                        </svg>
                    </div>

                    <div className='contact-us-sbm-poster-wrapper'>
                        <img className='contact-us-sbm-poster' draggable="false" alt='left poster' />
                    </div>
                    <div className='contact-us-sbm-section-wrapper'>
                        <div className='contact-us-sbm-section'>
                            <div>
                                <h4 className='contact-us-sbm-section-title'>request submitted</h4>
                                <p className='contact-us-sbm-section-description mb'>Thanks for requesting to book. We’ll be in touch soon.</p>
                            </div>

                            <div className='contact-us-sbm-section-action'>
                                <button className='contact-us-sbm-section-submit-btn' type='button' onClick={onBackToHome}>back to homepage</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ContactUs;