import axios from './axios';

// const baseApiUrl = process.env.REACT_APP_API_URL;
const endPoint = '/contact-us';


export const onSaveContactUs = (body) => {
    return axios.post(`${endPoint}`, {
        ...body
    });
}