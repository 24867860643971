import axios from './axios';

// const baseApiUrl = process.env.REACT_APP_API_URL;
const endPoint = '/music-studio';


export const create = (body) => {
    return axios.post(`${endPoint}`, {
        ...body
    });
}