import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './AccountSignIn.component.scss';

import AppContext from '../../context/app/app.context';
import { login, setCurrentUserSession } from '../../services/user.service';
import Regexp from '../../constants/regexp';


function AccountSignIn() {

    const { openModal } = useContext(AppContext);

    const navigate = useNavigate();
    const [encodeKey, setEncodeKey] = useState();
    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
        setEncodeKey(`${window.screen.height}${window.screen.width}${window.screen.colorDepth}${new Date().getTime()}`);
    }, []);

    const loginDefaultValues = {
        email: '',
        password: ''
    };
    const { register, handleSubmit, formState: { errors, touchedFields, isDirty, isValid }, reset } = useForm({
        mode: 'all',
        defaultValues: loginDefaultValues
    });
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const [responseErrorMsg, setResponseErrorMsg] = useState('');

    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                setIsRequestAlive(true);
                const loginBody = data;
                login(loginBody, encodeKey).then((res) => {
                    if (res.data.code === 'OK') {
                        /** re-set form fields and error message */
                        setResponseErrorMsg('');
                        reset(loginDefaultValues);
                        /** set user and token info to localStorage */
                        setCurrentUserSession(res.data);
                        /** after setting/save in localStorage navigate to account page */
                        navigate('/account');
                    }
                    setIsRequestAlive(false);
                }).catch((err) => {
                    if (err.response.data.code === "E_NOT_FOUND") {
                        setResponseErrorMsg('Email not registered, Please register it first then try to login.');
                    } else if (err.response.data.code === "E_BAD_REQUEST") {
                        setResponseErrorMsg('Invalid credentials, Please check E-mail and Password then try again.');
                    }
                    setIsRequestAlive(false);
                    console.log('Error while login user', err);
                });
            }
        } catch (error) {
            setIsRequestAlive(false);
            console.log('Error while login user', error);
        }
    }

    const onForgottenPassword = () => {
        openModal('forgottenPassword');
    }

    return (
        <div className="account-sign-in-container">

            <div className='account-sign-in-wrapper'>
                <form onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
                    <div className='account-sign-in-form'>
                        <div className='account-sign-in-form-header'>
                            <h4 className='account-sign-in-form-header-title'>login</h4>
                            <h4 className='account-sign-in-form-header-info'>Sign in below to access your account.</h4>
                        </div>

                        <div className='account-sign-in-form-body'>
                            <div>
                                <div className='account-sign-in-form-body-item'>
                                    <input className='account-sign-in-form-body-item-input' type='text' name='email' placeholder='email'
                                        {...register('email', {
                                            required: 'This field is required.',
                                            pattern: {
                                                value: Regexp._EMAIL,
                                                message: 'Please enter a valid e-mail address.'
                                            }
                                        })} />
                                </div>
                                {
                                    (touchedFields.email && errors.email) && (<h4 className='account-sign-in-form-body-item-err-msg'>
                                        {errors.email.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='account-sign-in-form-body-item'>
                                    <input className='account-sign-in-form-body-item-input' type='password' name='password' placeholder='password'
                                        {...register('password', {
                                            required: 'This field is required.',
                                            // pattern: {
                                            //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                                            //     message: 'Password pattern does not match, please check it.'
                                            // }
                                        })} />
                                </div>
                                {
                                    (touchedFields.password && errors.password) && (<h4 className='account-sign-in-form-body-item-err-msg'>
                                        {errors.password.message}
                                    </h4>)
                                }
                            </div>
                        </div>

                        {responseErrorMsg && <h4 className='account-sign-in-form-not-registered'>
                            {responseErrorMsg}
                        </h4>}

                        <div className='account-forgotten-link-wrapper'>
                            <h4 className='account-forgotten-link-text'>Forgotten your password? <span className='account-forgotten-link' onClick={onForgottenPassword}>click here</span></h4>
                        </div>
                    </div>

                    <div className='account-sign-in-action-wrapper'>
                        <button className='account-sign-in-action-btn' type='submit' disabled={isRequestAlive}>login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AccountSignIn;