import React, { useEffect, useState } from 'react';

import './PrivacyPolicy.component.scss';
import LOADER from '../../assets/images/LOADING_ICON.gif';

import { getPrivacyPolicyContent } from '../../services/privacy-policy-or-t-and-cs.service';


const PrivacyPolicy = () => {

    const [privacyPolicy, setPrivacyPolicy] = useState(null);
    const [isRequestAlive, setIsRequestAlive] = useState(true);

    useEffect(() => {
        try {
            setIsRequestAlive(true);
            getPrivacyPolicyContent().then((res) => {
                if (res.data.code === "OK") {
                    setPrivacyPolicy(res.data.data);
                    setIsRequestAlive(false);
                }
            }).catch((err) => {
                setIsRequestAlive(false);
                console.log('Error while getting privacy policy', err);
            });
        } catch (error) {
            console.log('Error while getting privacy policy', error);
        }
    }, []);

    return (
        <>
            {!isRequestAlive && privacyPolicy &&
                <div className='privacy-policy-container'>

                    <div className='privacy-policy-head'>
                        <div className='privacy-policy-head-wrapper'>
                            <h4 className='privacy-policy-head-title'>privacy-policy</h4>
                            {privacyPolicy?.shortIntro && <h4 className='privacy-policy-head-description'>{privacyPolicy.shortIntro}</h4>}
                        </div>
                    </div>

                    <div className='privacy-policy-description' dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}></div>
                </div>
            }
            {isRequestAlive &&
                <div className='privacy-policy-loader'>
                    <img className='privacy-policy-loader-img' src={LOADER} alt='loader' />
                </div>
            }
        </>
    )
}

export default PrivacyPolicy;