import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './Account.component.scss';
import LOADER from '../../assets/images/LOADING_ICON.gif';

import AppContext from '../../context/app/app.context';
import { isUserLoggedIn, logout, userDashboard } from '../../services/user.service';
import { upcomingPreviousBookingDate } from '../../services/utils.service';


function Account() {

    const { setAppConfig } = useContext(AppContext);
    const navigate = useNavigate();
    // const [isMembershipActive, setIsMembershipActive] = useState(appConfig?.membership?.isMembershipActive ?? false);
    const [userDetails, setUserDetails] = useState({});
    const [upcomingBooking, setUpcomingBooking] = useState([]);
    const [previousBooking, setPreviousBooking] = useState([]);
    const [isRequestAlive, setIsRequestAlive] = useState(true);

    const orderItemsName = {
        FULL: 'Full Pitch',
        HALF: 'Half Pitch',
        THIRD: 'Third Pitch',
        MUSIC_STUDIO: 'Music Studio',
        GAMES_ROOM: 'Games Room'
    };

    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
        if (!isUserLoggedIn()) {
            navigate('/');
        }
        getUserDashboardDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     setIsMembershipActive(appConfig?.membership?.isMembershipActive ?? false);
    // }, [appConfig]);

    const getUserDashboardDetails = () => {
        setIsRequestAlive(true);
        userDashboard().then((res) => {
            if (res.data.code === "OK") {

                const response = res.data.data;
                const userInfo = res.data.data.user;

                // if (userInfo?.membership) {
                //     userInfo.membership.membershipStartDateD = userInfo.membership.membershipStartDate?.split("T")[0].split("-").reverse().join(".");
                //     userInfo.membership.membershipEndDateD = userInfo.membership.membershipEndDate?.split("T")[0].split("-").reverse().join(".");
                // }

                // setAppConfig((prevState) => {
                //     if (!prevState?.membership || (prevState.membership && (prevState.membership?.membershipType !== userInfo.membership?.membershipType || prevState.membership?.isMembershipActive !== userInfo.membership?.isMembershipActive))) {
                //         if (!prevState.membership) prevState.membership = {};

                //         prevState.membership.isMembershipActive = userInfo.membership.isMembershipActive;
                //         prevState.membership.membershipStartDate = userInfo.membership.membershipStartDate;
                //         prevState.membership.membershipEndDate = userInfo.membership.membershipEndDate;
                //         prevState.membership.membershipType = userInfo.membership.membershipType;
                //     }

                //     if (!prevState?.subscriptionV2 || (prevState.subscriptionV2?.length && userInfo?.subscriptionV2?.length && prevState.subscriptionV2[0].cancel_at_period_end !== userInfo?.subscriptionV2[0].cancel_at_period_end)) {
                //         prevState.subscriptionV2 = userInfo.subscriptionV2;
                //     }

                //     return prevState ? { ...prevState } : prevState;
                // });

                setAppConfig((prevState) => {
                    prevState.membershipPaid = userInfo.membershipPaid;
                    prevState.membershipApproved = userInfo.membershipApproved;

                    return prevState ? { ...prevState } : prevState;
                });

                if (response.upcomingBooking) {
                    const upcomingBooking = [];
                    for (const ub of response?.upcomingBooking) {
                        for (const orderItem of ub.orderItems) {
                            upcomingBooking.push({
                                ...orderItem,
                                orderNo: ub.orderNo
                            });
                        }
                    }
                    upcomingBooking.sort((a, b) => new Date(`${a.date.substring(0, 11)}${a.startTime}`) - new Date(`${b.date.substring(0, 11)}${b.startTime}`));
                    setUpcomingBooking([...upcomingBooking]);
                }

                if (response.previousBooking) {
                    const previousBooking = [];
                    for (const ub of response?.previousBooking) {
                        for (const orderItem of ub.orderItems) {
                            previousBooking.push({
                                ...orderItem,
                                orderNo: ub.orderNo
                            });
                        }
                    }
                    previousBooking.sort((a, b) => new Date(`${b.date.substring(0, 11)}${b.startTime}`) - new Date(`${a.date.substring(0, 11)}${a.startTime}`));
                    setPreviousBooking([...previousBooking]);
                }

                if (userInfo) setUserDetails({ ...userInfo });
            }
            setIsRequestAlive(false);
        }).catch((err) => {
            setIsRequestAlive(false);
            console.log('Error while getting user dashboard details', err);
        });
    }

    // const onResumeMembership = () => {
    //     if (userDetails?.subscriptionV2?.length && userDetails?.subscriptionV2[0]?.cancel_at_period_end === "true") {
    //         openModal('membershipResume');
    //     }
    // }

    // const openMembershipCancelModal = () => {
    //     openModal('membershipCancel', { isOpenViaAccountPage: true });
    // }



    return (
        <div className="account-container">

            {!isRequestAlive &&
                <div className='account-wrapper'>

                    <div className='account-my-details-membership-wrapper'>
                        <div className='account-my-details-membership'>
                            <h4 className='account-my-details-membership-title'>my details</h4>
                            <div className='account-my-details-membership-item'>{`${userDetails?.firstName} ${userDetails?.lastName}`}</div>
                            <div className='account-my-details-membership-item'>{userDetails?.phoneNumber}</div>
                            <div className='account-my-details-membership-item email'>{userDetails?.email}</div>
                            {userDetails?.address?.addLine1 && <div className='account-my-details-membership-item'>{userDetails?.address?.addLine1}</div>}
                            {userDetails?.address?.addLine2 && <div className='account-my-details-membership-item'>{userDetails?.address?.addLine2}</div>}
                            {userDetails?.address?.city && <div className='account-my-details-membership-item'>{userDetails?.address?.city}</div>}
                            {userDetails?.address?.postcode && <div className='account-my-details-membership-item'>{userDetails?.address?.postcode}</div>}
                            {userDetails?.address?.phoneNumber && <div className='account-my-details-membership-item'>{userDetails?.address?.phoneNumber}</div>}
                            <div className='account-my-details-membership-item'>
                                <NavLink className='update-link' to='/update-my-information' state={{ isForUpdate: true }}>
                                    update my information
                                </NavLink>
                            </div>
                            <div className='account-my-details-membership-item update-link' onClick={logout}>logout</div>
                        </div>

                        {
                            // membership flow changed os old flow commented
                            // <div className='account-my-details-membership'>
                            //     <h4 className='account-my-details-membership-title'>membership</h4>
                            //     {isMembershipActive ?
                            //         <>
                            //             <div className='account-my-details-membership-item'>{userDetails?.membership?.membershipType} membership</div>
                            //             <div className='account-my-details-membership-item'>duration - annual</div> {/* annual(12) Semi-Annual(6) Quarterly(3) Monthly(1) */}
                            //             <div className='account-my-details-membership-item'>start date - {userDetails?.membership?.membershipStartDateD}</div>
                            //             <div className='account-my-details-membership-item'>end date - {userDetails?.membership?.membershipEndDateD}</div>
                            //             {
                            //                 userDetails?.subscriptionV2?.length && userDetails?.subscriptionV2[0]?.cancel_at_period_end === "true" ?
                            //                     <div className='account-my-details-membership-item update-link' onClick={onResumeMembership}>resume my membership</div>
                            //                     :
                            //                     <div className='account-my-details-membership-item update-link' onClick={openMembershipCancelModal}>cancel my membership</div>
                            //             }
                            //         </> : <>
                            //             <NavLink className='account-my-details-membership-item update-link' to='/membership'>you don't have membership subscription</NavLink>
                            //         </>}
                            // </div>
                        }
                    </div>

                    <div className='account-previous-upcoming-booking-wrapper'>
                        <div className='account-previous-upcoming-booking'>
                            <h4 className='account-previous-upcoming-title'>upcoming bookings</h4>
                            <div className='responsive-table'>

                                {upcomingBooking?.length > 0 &&
                                    <div className='table-row'>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>order</div>
                                        </div>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>date</div>
                                        </div>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>order type</div>
                                        </div>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>time</div>
                                        </div>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>payment</div>
                                        </div>
                                    </div>
                                }

                                {upcomingBooking?.map((order, i) => {
                                    return <Fragment key={i}>
                                        <div className='table-row'>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>order</div>
                                                <div className='table-row-cell-value'>{`#${order?.orderNo || ''}`}</div>
                                            </div>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>date</div>
                                                <div className='table-row-cell-value'>{upcomingPreviousBookingDate(order?.date)}</div>
                                            </div>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>name</div>
                                                <div className='table-row-cell-value'>{orderItemsName[order.assetType]}</div>
                                            </div>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>time</div>
                                                <div className='table-row-cell-value'>{`${order?.startTime}-${order?.endTime}`}</div>
                                            </div>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>payment</div>
                                                <div className='table-row-cell-value'>{`£${order?.amountToPay?.toFixed(2)}`}</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                })}
                                {!upcomingBooking?.length && <div className='table-row empty-list'>you have no upcoming booking record</div>}
                            </div>
                        </div>

                        <div className='account-previous-upcoming-booking'>
                            <h4 className='account-previous-upcoming-title'>previous bookings</h4>
                            <div className='responsive-table'>

                                {previousBooking?.length > 0 &&
                                    <div className='table-row'>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>order</div>
                                        </div>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>date</div>
                                        </div>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>order type</div>
                                        </div>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>time</div>
                                        </div>
                                        <div className='table-row-cell'>
                                            <div className='table-row-cell-title'>payment</div>
                                        </div>
                                    </div>
                                }

                                {previousBooking?.map((order, i) => {
                                    return <Fragment key={i}>
                                        <div className='table-row'>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>order</div>
                                                <div className='table-row-cell-value'>{`#${order?.orderNo || ''}`}</div>
                                            </div>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>date</div>
                                                <div className='table-row-cell-value'>{upcomingPreviousBookingDate(order?.date)}</div>
                                            </div>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>order type</div>
                                                <div className='table-row-cell-value'>{orderItemsName[order.assetType]}</div>
                                            </div>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>time</div>
                                                <div className='table-row-cell-value'>{`${order?.startTime}-${order?.endTime}`}</div>
                                            </div>
                                            <div className='table-row-cell'>
                                                <div className='table-row-cell-title'>payment</div>
                                                <div className='table-row-cell-value'>{`£${order?.amountToPay?.toFixed(2)}`}</div>
                                            </div>
                                        </div>
                                    </Fragment>
                                })}
                                {!previousBooking?.length && <div className='table-row empty-list'>you have no previous booking record</div>}
                            </div>
                        </div>
                    </div>
                </div>
            }

            {isRequestAlive &&
                <div className='account-loader'>
                    <img className='news-article-loader-img' src={LOADER} alt='loader' />
                </div>
            }
        </div>
    )
}

export default Account;