import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './MembershipPurchaseResponse.component.scss';

import infoBox from '../../assets/images/info-box.svg';
import membershipRequestSubmitted from '../../assets/images/membership-request-submitted.png';
import membershipRequestSubmittedM from '../../assets/images/membership-request-submitted-m.png';

import AppContext from '../../context/app/app.context';
import { isUserLoggedIn } from '../../services/user.service';


const MembershipPurchaseResponse = ({ membershipPaid }) => {

    const { appConfig, openModal } = useContext(AppContext);

    const stripe = useRef();
    const [membershipPaymentStatus, setMembershipPaymentStatus] = useState('');

    const navigate = useNavigate();

    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
        if (!isUserLoggedIn()) {
            navigate('/');
        }

        if (appConfig.STRIPE_PUBLISH_KEY) {
            stripe.current = window.Stripe(appConfig.STRIPE_PUBLISH_KEY);
        }

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret") || '';
        if ((!clientSecret || !appConfig.STRIPE_PUBLISH_KEY) && !(appConfig.membershipApproved || appConfig.membershipPaid) ) {
            setMembershipPaymentStatus("failed");
            return;
        }

        if(clientSecret) {
            stripe.current.retrievePaymentIntent(clientSecret)?.then(({ paymentIntent }) => {
                switch (paymentIntent.status) {
                    case "succeeded":
                        setMembershipPaymentStatus("paid");
                        break;
                    case "processing":
                        setMembershipPaymentStatus("Your payment is processing.");
                        break;
                    default:
                        setMembershipPaymentStatus("failed");
                        break;
                }
            }).catch((err) => {
                setMembershipPaymentStatus("failed");
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onContactUs = () => {
        openModal('contact');
    }


    return (
        <div className="membership-purchase-response-container">

            <div className='membership-success-wrap'>

                <div className='header-wrap'>
                    <div className='title-wrap'>
                        {appConfig.membershipApproved ? <>
                            <div className='title-icon'>
                                <svg width="78" height="78" viewBox="0 0 78 78" fill="none">
                                    <path d="M63.7354 0H14.2949L0 14.2949V63.7354L14.2949 78H63.7354L78 63.7354V14.2949L63.7354 0Z" fill="#DAFF00" />
                                    <path d="M59.5471 21H55.4891L31.5352 44.949L22.6017 35.987H18.4832L16 38.4697V42.5268L29.476 56H33.5642L61.9394 27.5701L61.9697 26.9645C62 25.8443 62 25.2085 62 24.1185V23.4827L59.5471 21Z" fill="#282727" />
                                </svg>
                            </div>
                            <h4 className='title'>request approved!</h4>
                        </> : <>
                                {(appConfig.membershipPaid || membershipPaymentStatus === 'paid') && <>
                                    <div className='title-icon'>
                                        <svg width="78" height="78" viewBox="0 0 78 78" fill="none">
                                            <path d="M63.7354 0H14.2949L0 14.2949V63.7354L14.2949 78H63.7354L78 63.7354V14.2949L63.7354 0Z" fill="#DAFF00" />
                                            <path d="M59.5471 21H55.4891L31.5352 44.949L22.6017 35.987H18.4832L16 38.4697V42.5268L29.476 56H33.5642L61.9394 27.5701L61.9697 26.9645C62 25.8443 62 25.2085 62 24.1185V23.4827L59.5471 21Z" fill="#282727" />
                                        </svg>
                                    </div>
                                    <h4 className='title'>request submitted!</h4>
                                </>}
                        </>}
                    </div>
                    {membershipPaymentStatus !== 'failed' && <>
                        <p className='description'>
                            Thanks for your request.
                        </p>
                    </>}
                    {membershipPaymentStatus === 'failed' && <>
                        <div className='title-wrap'>
                            <div className='title-icon'>
                                <svg width="78" height="78" viewBox="0 0 78 78" fill="none">
                                    <path d="M63.7354 0H14.2949L0 14.2949V63.7354L14.2949 78H63.7354L78 63.7354V14.2949L63.7354 0Z" fill="#DAFF00" />
                                    <path d="M24.842 61V57.16L34.802 40.12L25.202 23.74V19.9H32.882L39.482 32.08H39.722L46.322 19.9H53.762V23.74L44.162 40.12L54.182 57.16V61H46.502L39.542 48.16H39.362L32.282 61H24.842Z" fill="#282727" />
                                </svg>
                            </div>
                            <h4 className='title'>payment failed!</h4>
                        </div>
                    </>}
                </div>

                <picture>
                    <source media='(max-width: 768px)' srcSet={membershipRequestSubmittedM} />
                    <img className='success-img' srcSet={membershipRequestSubmitted} draggable="false" alt="" />
                </picture>

                <div className='help-wrapper'>
                    <img className='help-icon' src={infoBox} draggable="false" alt='' />
                    <h4 className='help-link'>need more help? <span className='manage-membership-link' onClick={onContactUs}>contact us</span></h4>
                </div>
            </div>

        </div>
    )
}

export default MembershipPurchaseResponse;