import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './Header.component.scss';

import AppContext from '../../context/app/app.context';
import { isUserLoggedIn, logout } from '../../services/user.service';

import adidas3BarLogo from '../../assets/images/adidas-3-bar-logo.png';


function Header() {

    const { popupModalMenu, openModal } = useContext(AppContext);

    const { pathname } = useLocation();
    const [menuToggler, setMenuToggler] = useState(false);

    /** component will-mount and did-mount and rerun based on array dependency */
    useEffect(() => {
        const isAnyModelShowed = popupModalMenu.some(item => item.isShow);
        if (menuToggler || isAnyModelShowed) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        /** it will register the window resize event
         *  for the latest value of 'menuToggler' whenever it changed then re-add event listener
         */
        const callback = () => {
            if (menuToggler) {
                /** 992px it is based on break point of min to max devices */
                if (window.innerWidth <= 992) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }
            }
        }
        window.addEventListener('resize', callback);
        /** this return callback function executes at when current component is unload from DOM. */
        return () => {
            window.removeEventListener('resize', callback);
        }
    }, [menuToggler, popupModalMenu]);

    const onBrandClick = () => {
        if (pathname !== '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const onCollapseMenu = () => {
        if (menuToggler) { setMenuToggler(false); }
        window.scrollTo(0, 0);
    }

    const onToggleModalMenu = (modalName) => {
        window.scrollTo(0, 0);
        setMenuToggler(false);
        openModal(modalName);
    }


    return (
        <div className="header-wrapper">
            {
                <NavLink
                    className="brand-wrapper"
                    id="brand-wrapper"
                    to="/"
                    onClick={onBrandClick}
                >
                    <img src={adidas3BarLogo} alt='Adidas 3 Bar Logo' />
                    <h4 className='brand-name'>selhurst sports arena <span className='brand-name-right'>- home of #merky fc</span></h4>
                </NavLink>
            }

            <div className='nav-container'>
                <div className='nav-menu-icon' onClick={() => setMenuToggler(!menuToggler)}>MENU</div>
                <div className={'nav-menu-wrapper' + (menuToggler ? ' m-nav-menu-wrapper' : '')}>
                    <div className='nav-menu-close-btn-wrapper' onClick={() => setMenuToggler(!menuToggler)}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#1E1E1E" />
                        </svg>
                    </div>
                    <NavLink
                        className='nav-menu-link'
                        onClick={onCollapseMenu}
                        to="/booking"
                    >
                        book
                    </NavLink>
                    {/* <div className='nav-menu-link'
                        onClick={() => onToggleModalMenu('signup')}>Sign in/up</div> */}
                    {/* <NavLink
                        className='nav-menu-link'
                        onClick={onCollapseMenu}
                        to="/venue-hire"
                    >
                        venue hire
                    </NavLink> */}

                    {isUserLoggedIn() &&
                        <NavLink
                            className='nav-menu-link'
                            onClick={onCollapseMenu}
                            to="/membership"
                        >
                            membership
                        </NavLink>
                    }
                    {/* <NavLink
                        className='nav-menu-link'
                        onClick={onCollapseMenu}
                        to="/membership"
                    >
                        membership
                    </NavLink> */}

                    <div className='nav-menu-link'
                        onClick={() => onToggleModalMenu('contact')}>contact</div>

                    {/* <NavLink
                        className='nav-menu-link'
                        onClick={onCollapseMenu}
                        to="/news"
                    >
                        news
                    </NavLink> */}
                    {isUserLoggedIn() && <div className='nav-menu-link logout-link' onClick={() => { onCollapseMenu(); logout(); }}>logout</div>}
                    <NavLink
                        className='nav-menu-link p-0'
                        onClick={onCollapseMenu}
                        to={(isUserLoggedIn() ? 'account' : 'account-sign-up-in')}
                    >
                        <span className='nav-menu-link-label'>account</span>
                        <svg className='nav-menu-link-icon' width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M8.59991 3.52062C8.15748 3.18223 7.60509 2.98072 7.00738 2.97998C5.55174 2.97768 4.3633 4.16062 4.36133 5.6147C4.35895 6.95218 5.35771 8.06434 6.64789 8.23727C6.7617 8.25246 6.87781 8.26076 6.99564 8.26076C7.2745 8.26108 7.54376 8.21814 7.79634 8.13783C8.86325 7.79944 9.64013 6.80109 9.6421 5.62595C9.64284 5.18352 9.53412 4.76572 9.34124 4.39875C9.16091 4.05453 8.90661 3.75457 8.59991 3.52054V3.52062Z" fill="#B3B4B4" />
                            <path d="M11.4676 0H2.53241L0 2.53241V11.4676L2.53241 14H11.4676L14 11.4676V2.53241L11.4676 0ZM12.8504 10.9339L11.0344 12.7499C11.0268 12.7137 11.0171 12.6726 11.0059 12.6251L11.0038 12.6162C10.5234 10.5871 8.69295 9.30191 6.73855 9.43962C6.53318 9.45423 6.32625 9.48421 6.11965 9.53117C5.92685 9.57461 5.73355 9.6325 5.5419 9.70567C4.13158 10.2426 3.28843 11.2885 2.96852 12.7528L1.1496 10.9339V2.95111L3.00859 1.09212H10.9914L12.8504 2.95111V10.9339Z" fill="#B3B4B4" />
                            <path d="M11.4676 0H2.53241L0 2.53241V11.4676L2.53241 14H11.4676L14 11.4676V2.53241L11.4676 0ZM12.8504 10.9339L11.0344 12.7499C11.0268 12.7137 11.0171 12.6726 11.0059 12.6251L11.0038 12.6162C10.5234 10.5871 8.69295 9.30191 6.73855 9.43962C6.53318 9.45423 6.32625 9.48421 6.11965 9.53117C5.92685 9.57461 5.73355 9.6325 5.5419 9.70567C4.13158 10.2426 3.28843 11.2885 2.96852 12.7528L1.1496 10.9339V2.95111L3.00859 1.09212H10.9914L12.8504 2.95111V10.9339Z" stroke="#B3B4B4" />
                        </svg>
                    </NavLink>

                    <a
                        className='nav-menu-link'
                        href="https://www.merkyfc.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        merkyfc
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Header;