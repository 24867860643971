import axios from './axios';

// const baseApiUrl = process.env.REACT_APP_API_URL;
const endPoint = '/stripe';


export const createMembership = (body) => {
    return axios.post(`${endPoint}/create-checkout-session`, body);
}

export const cancelMembership = () => {
    return axios.post(`${endPoint}/cancel-subscription`, {});
}

export const resumeMembership = () => {
    return axios.post(`${endPoint}/resume-subscription`, {});
}

export const membershipCancellationFeedback = (body) => {
    return axios.post(`/cancellation-feedback`, body);
}

export const createMembershipDetails = (body) => {
    return axios.post(`/membership/create`, body);
}