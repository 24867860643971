import axios from './axios';
import { getSessionHeader } from './user.service';

// const baseApiUrl = process.env.REACT_APP_API_URL;
const endPoint = '/order';


export const getOrder = (body) => {
    const headers = getSessionHeader() ?? {};

    return axios.post(`${endPoint}`, body, { headers: headers });
}

export const removeOrderOverviewItem = (body) => {
    const headers = getSessionHeader() ?? {};

    return axios.post(`${endPoint}/remove-to-order-overview`, body, { headers: headers });
}

export const getConfirmedOrderDetails = (paymentIntentId) => {
    const headers = getSessionHeader() ?? {};

    return axios.get(`${endPoint}/confirmed-order/${paymentIntentId}`, { headers: headers });
}

// https://www.30secondsofcode.org/react/s/use-unload/