import Axios from 'axios';


function authRequestInterceptor(config) {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.authorization = `${token}`;
    }
    config.headers.Accept = 'application/json';
    return config;
}

const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);

// axios.interceptors.response.use(
//   (response) => {
//     return response.data;
//   },
//   (error) => {
//     const code = error?.response?.data?.code;

//     if (code === "E_UNAUTHORIZED") {
//       const userDetails = storage.getUserDetails();
//       if (userDetails?.userType !== 1) {
//         const projectShortName = storage.getProjectShortName()
//         if (projectShortName) {
//           window.location.assign(`${window.location.origin}/${projectShortName}/login`);
//           storage.clearToken();
//           return;
//         }
//       }

//       storage.clearToken();
//       window.location.assign(`${window.location.origin}/login`);
//     }

//     return Promise.reject(error);
//   }
// );

export default axios;