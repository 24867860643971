import CryptoJS from 'crypto-js';
import { _dayNames, _monthsNames } from '../constants/utils';

// const baseApiUrl = process.env.REACT_APP_API_URL;

/**
 * @param {''} dateString like YYYY-MM-DD in digits example like (2023-01-01)
 * @returns monthName date with suffix, year example like (January 1st, 2023)
 */
export const newsDateFormat = (dateString) => {
    if (!(/^\d{4}-\d{2}-\d{2}/.test(dateString))) return dateString;

    const newsDate = new Date(dateString);
    const month = _monthsNames.long[newsDate.getMonth()];
    const day = newsDate.getDate();
    const year = newsDate.getFullYear();

    /** Add the ordinal suffix to the day */
    let dayWithSuffix;
    if (day === 1 || day === 21 || day === 31) {
        dayWithSuffix = `${day}st,`;
    } else if (day === 2 || day === 22) {
        dayWithSuffix = `${day}nd,`;
    } else if (day === 3 || day === 23) {
        dayWithSuffix = `${day}rd,`;
    } else {
        dayWithSuffix = `${day}th,`;
    }

    return `${month} ${dayWithSuffix} ${year}`;
};

export const upcomingPreviousBookingDate = (dateString) => {
    return newsDateFormat(dateString);
};

/**
 * @param {''} dateString like YYYY-MM-DD in digits example like (2023-01-01)
 * @returns date string, example like (Sunday 1st October)
 */
export const bookingSlotDate = (dateString) => {
    if (!(/^\d{4}-\d{2}-\d{2}/.test(dateString))) return dateString;

    const newsDate = new Date(dateString);
    const day = _dayNames.long[newsDate.getDay()];
    const date = newsDate.getDate();
    const month = _monthsNames.long[newsDate.getMonth()];

    /** Add the ordinal suffix to the day */
    let dateWithSuffix;
    if (date === 1 || date === 21 || date === 31) {
        dateWithSuffix = `${date}st,`;
    } else if (date === 2 || date === 22) {
        dateWithSuffix = `${date}nd,`;
    } else if (date === 3 || date === 23) {
        dateWithSuffix = `${date}rd,`;
    } else {
        dateWithSuffix = `${date}th,`;
    }

    return `${day} ${dateWithSuffix} ${month}`;
};

/**
 * @param {''} dateString like YYYY-MM-DD in digits example like (2023-01-01)
 * @returns date string, example like (Sun 1 Oct)
 */
export const bookingSlotDateMin = (dateString) => {
    if (!(/^\d{4}-\d{2}-\d{2}/.test(dateString))) return dateString;

    const newsDate = new Date(dateString);
    const day = _dayNames.short[newsDate.getDay()];
    const date = newsDate.getDate();
    const month = _monthsNames.short[newsDate.getMonth()];

    return `${day} ${date} ${month}`;
};

export const getMongooseObjectId = (data) => {
    const id = data?.trim().slice(-24);
    if (/^[0-9a-fA-F]{24}$/.test(id)) {
        return id;
    } else return false;
}


export const decryptData = (response) => {
    const bytes = CryptoJS.AES.decrypt(response.data, response.date);
    if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return response;
}

export const encodeData = (data, encodeKey) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), encodeKey).toString();
}