/** for home page */
import bookPitch from '../assets/images/book-a-pitch.png';
import bookPitchM from '../assets/images/book-a-pitch-m.png';
import bookStudio from '../assets/images/book-a-studio.png';
import bookStudioM from '../assets/images/book-a-studio-m.png';
import gamesRoom from '../assets/images/games-room.png';
import gamesRoomM from '../assets/images/games-room-m.png';

/** for booking page */
import bookingPitch from '../assets/images/pitch-hero.png';
import bookingPitchM from '../assets/images/pitch-hero-m.png';
import musicStudioHero from '../assets/images/music-studio-hero.png';
import musicStudioHeroM from '../assets/images/music-studio-hero-m.png';
import gamesRoomHero from '../assets/images/games-room-hero.jpg';
import gamesRoomHeroM from '../assets/images/games-room-hero-m.png';


export const _bookingTypes = [
    {
        title: 'book a pitch',
        bookingTitle: 'booking a pitch',
        type: 'pitch',
        image: {
            default: bookPitch,
            list: [
                {
                    media: '(max-width: 768px)',
                    srcset: bookPitchM
                },
                {
                    media: '(min-width: 769px)',
                    srcset: bookPitch
                }
            ]
        },
        heroImage: {
            default: bookingPitch,
            list: [
                {
                    media: '(max-width: 768px)',
                    srcset: bookingPitchM
                },
                {
                    media: '(min-width: 769px)',
                    srcset: bookingPitch
                }
            ]
        },
        description: 'Whether it’s competitive games or casual kickabouts with friends, our 3G pitch is a safe space for everyone to show their flair. Book 5-aside, 7-aside or 11-aside. We can loan you balls, bibs and corner flags.'
    },
    {
        title: 'book a music studio',
        bookingTitle: 'booking a music studio',
        type: 'music_studio',
        image: {
            default: bookStudio,
            list: [
                {
                    media: '(max-width: 768px)',
                    srcset: bookStudioM
                },
                {
                    media: '(min-width: 769px)',
                    srcset: bookStudio
                }
            ]
        },
        heroImage: {
            default: musicStudioHero,
            list: [
                {
                    media: '(max-width: 768px)',
                    srcset: musicStudioHeroM
                },
                {
                    media: '(min-width: 769px)',
                    srcset: musicStudioHero
                }
            ]
        },
        description: 'Our #Merky FC Studio has a recording booth and control room for making music and podcasts. We run regular music programmes for anyone who wants to learn how to produce their own music.'
    },
    {
        title: 'book gaming room',
        bookingTitle: 'booking gaming room',
        type: 'games_room',
        image: {
            default: gamesRoom,
            list: [
                {
                    media: '(max-width: 768px)',
                    srcset: gamesRoomM
                },
                {
                    media: '(min-width: 769px)',
                    srcset: gamesRoom
                }
            ]
        },
        heroImage: {
            default: gamesRoomHero,
            list: [
                {
                    media: '(max-width: 768px)',
                    srcset: gamesRoomHeroM
                },
                {
                    media: '(min-width: 769px)',
                    srcset: gamesRoomHero
                }
            ]
        },
        description: 'Play and stream on our Playstation 5, X Box and PCs. Book with friends or join one of our EA programmes to learn about the world of gaming.'
    }
];