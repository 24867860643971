import React, { useContext, useEffect, useState } from 'react';

import './Membership.component.scss';

import MembershipNew from '../membership-new/MembershipNew.component';
import MembershipPurchaseResponse from '../membership-purchase-response/MembershipPurchaseResponse.component';
import AppContext from '../../context/app/app.context';

import { isUserLoggedIn } from '../../services/user.service';
import { useNavigate } from 'react-router-dom';


const Membership = () => {

    const { appConfig } = useContext(AppContext);
    const [membershipPaid, setMembershipPaid] = useState(appConfig?.membershipPaid ?? false);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!isUserLoggedIn()) {
            navigate('/');
        }
        setMembershipPaid(appConfig?.membershipPaid);
        // eslint-disable-next-line
    }, [appConfig]);

    return (
        <>
            {membershipPaid ? <MembershipPurchaseResponse membershipPaid={membershipPaid} /> : <MembershipNew />}
        </>
    )
}

export default Membership;