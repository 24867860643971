import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import './GuestRegistration.component.scss';

import { createGuestUser } from '../../services/user.service';
import Regexp from '../../constants/regexp';


function GuestRegistration({ orderId, setGuestUserSubmitted }) {

    const registerDefaultValues = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
    };
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const { register, handleSubmit, formState: { errors, isDirty, isValid, touchedFields }, reset } = useForm({
        mode: 'all',
        defaultValues: registerDefaultValues
    });


    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                const body = {
                    orderId,
                    ...data
                };

                setIsRequestAlive(true);
                createGuestUser(body).then((res) => {
                    if (res.data.code === 'CREATED') {
                        reset(registerDefaultValues);
                        setGuestUserSubmitted(true);
                    }
                    setIsRequestAlive(false);
                }).catch((err) => {
                    setIsRequestAlive(false);
                    console.log('Error while create guest user', err);
                });
            }
        } catch (error) {
            setIsRequestAlive(false);
            console.log(`Error while create guest user`, error);
        }
    }


    return (
        <form className='guest-form-wrapper' onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
            <div className='guest-form'>
                <div className='guest-form-header'>
                    <h2 className='guest-form-header-title'>Please provide details below -</h2>
                </div>
                <div className='guest-form-body'>
                    <div>
                        <div className='guest-form-body-item'>
                            <input className='guest-form-body-item-input' type='text' name='firstName' placeholder='first name'
                                {...register('firstName', { required: 'This field is required.' })} />
                        </div>
                        {
                            (touchedFields.firstName && errors.firstName) && (<h4 className='guest-form-body-item-err-msg'>
                                {errors.firstName.message}
                            </h4>)
                        }
                    </div>
                    <div>
                        <div className='guest-form-body-item'>
                            <input className='guest-form-body-item-input' type='text' name='lastName' placeholder='last name'
                                {...register('lastName', { required: 'This field is required.' })} />
                        </div>
                        {
                            (touchedFields.lastName && errors.lastName) && (<h4 className='guest-form-body-item-err-msg'>
                                {errors.lastName.message}
                            </h4>)
                        }
                    </div>
                    <div>
                        <div className='guest-form-body-item'>
                            <input className='guest-form-body-item-input' type='text' name='phoneNumber' placeholder='contact number'
                                {...register('phoneNumber', {
                                    required: 'Contact Number is required.',
                                    pattern: {
                                        value: Regexp._MOBILE_NUMBER,
                                        message: 'Please enter valid contact number.'
                                    }
                                })} />
                        </div>
                        {
                            errors.phoneNumber && (<h4 className='guest-form-body-item-err-msg'>
                                {errors.phoneNumber.message}
                            </h4>)
                        }
                    </div>
                    <div>
                        <div className='guest-form-body-item'>
                            <input className='guest-form-body-item-input' type='text' name='email' placeholder='email'
                                {...register('email', {
                                    required: 'This field is required.',
                                    pattern: {
                                        value: Regexp._EMAIL,
                                        message: 'Please enter a valid e-mail address.'
                                    }
                                })} />
                        </div>
                        {
                            (touchedFields.email && errors.email) && (<h4 className='guest-form-body-item-err-msg'>
                                {errors.email.message}
                            </h4>)
                        }
                    </div>
                </div>
            </div>

            <div className='guest-action-wrapper'>
                <div className='guest-action'>
                    <button className={'guest-action-btn' + ((isRequestAlive || Object.keys(errors).length) ? ' in-valid' : '')} type='submit' disabled={isRequestAlive || Object.keys(errors).length}>
                        proceed to payment
                    </button>
                </div>
            </div>
        </form>
    )
}

export default GuestRegistration;