import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './AccountSignUp.component.scss';

import { getCurrentUser, getUserById, isUserLoggedIn, setCurrentUserSession, signup, updateUserById } from '../../services/user.service';
import Regexp from '../../constants/regexp';


function AccountSignUp() {

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const user = getCurrentUser();

    const [encodeKey, setEncodeKey] = useState();
    const registerDefaultValues = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        currentPassword: '',
        password: '',
        reEnterPassword: '',
        tAndCs: false
    };
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const { register, unregister, handleSubmit, formState: { errors, isDirty, isValid, touchedFields }, getValues, setValue, watch, setError, clearErrors, reset } = useForm({
        mode: 'all',
        defaultValues: registerDefaultValues
    });

    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
        setEncodeKey(`${window.screen.height}${window.screen.width}${window.screen.colorDepth}${new Date().getTime()}`);
        if (state?.isForUpdate && !isUserLoggedIn()) {
            navigate('/');
        } else if (state?.isForUpdate && isUserLoggedIn()) {
            getAndSetUserDetails();
        } else {
            unregister('currentPassword');
        }
        // eslint-disable-next-line
    }, []);


    const getAndSetUserDetails = () => {
        if (!user || !user?._id) {
            navigate('/');
            return;
        }

        setIsRequestAlive(true);
        getUserById(user._id).then((res) => {
            if (res.data.code === 'OK') {
                const response = res.data.data;
                setValue('firstName', response.firstName, { shouldValidate: true, shouldDirty: false });
                setValue('lastName', response.lastName, { shouldValidate: true, shouldDirty: false });
                setValue('email', response.email, { shouldValidate: true, shouldDirty: false });
                setValue('phoneNumber', response.phoneNumber, { shouldValidate: true, shouldDirty: false });
            }
            setIsRequestAlive(false);
        }).catch((err) => {
            console.log('Error while getting user details', err);
            setIsRequestAlive(false);
        });
    }


    const currentPasswordWatch = watch("currentPassword", "");
    const passwordWatch = watch("password", "");
    useEffect(() => {
        // if (state?.isForUpdate) return;
        if (touchedFields.reEnterPassword && getValues('password') !== getValues('reEnterPassword')) {
            if (getValues('reEnterPassword')) {
                setError('reEnterPassword', { type: 'validate', message: 'Password does not match.' });
            } else {
                setError('reEnterPassword', { type: 'required', message: 'This field is required.' });
            }
        } else if (errors.reEnterPassword && errors.reEnterPassword.type !== 'required') {
            clearErrors('reEnterPassword');
        }
        // eslint-disable-next-line
    }, [passwordWatch]);

    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                // setIsRequestAlive(true);
                const { currentPassword, password, reEnterPassword, tAndCs, ...registerBody } = data;

                if (state?.isForUpdate && user) {
                    if (currentPassword?.trim()) {
                        registerBody.currentPassword = currentPassword?.trim();
                        registerBody.password = password?.trim();
                    }

                    updateUserById(user._id, registerBody, encodeKey).then((res) => {
                        if (res.data.code === 'OK') {
                            reset(registerDefaultValues);
                            /** update user info to localStorage */
                            user.email = registerBody.email;
                            localStorage.setItem('user', JSON.stringify(user));
                            /** after setting/save in localStorage navigate to account page */
                            navigate('/account');
                        } else if (res.data.code === "E_BAD_REQUEST") {
                            setError('currentPassword', { type: 'invalid', message: 'Invalid current password, Please try again.' });
                        }
                        setIsRequestAlive(false);
                    }).catch((err) => {
                        if (err.response.data.code === "E_DUPLICATE") {
                            setError('email', { type: 'alreadyTaken', message: 'The email has already been taken, try to choose another one.' });
                        }
                        setIsRequestAlive(false);
                        console.log('Error while update user details', err);
                    });
                } else {

                    registerBody.password = password?.trim();
                    signup(registerBody, encodeKey).then((res) => {
                        if (res.data.code === 'CREATED') {
                            reset(registerDefaultValues);
                            /** set user and token info to localStorage */
                            setCurrentUserSession(res.data);
                            /** after setting/save in localStorage navigate to account page */
                            navigate('/account');
                        }
                        setIsRequestAlive(false);
                    }).catch((err) => {
                        if (err.response.data.code === "E_DUPLICATE") {
                            setError('email', { type: 'alreadyTaken', message: 'The email has already been taken, try to choose another one.' });
                        }
                        setIsRequestAlive(false);
                        console.log('Error while register user', err);
                    });
                }
            }
        } catch (error) {
            setIsRequestAlive(false);
            console.log(`Error while register user`, error);
        }
    }


    return (
        <div className="account-sign-up-container">

            <div className='account-sign-up-wrapper'>
                <form onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
                    <div className='account-sign-up-form'>
                        <div className='account-sign-up-form-header'>
                            {!state?.isForUpdate ? <>
                                <h2 className='account-sign-up-form-header-title'>sign up</h2>
                                <h4 className='account-sign-up-form-header-info'>Join the squad to book activities at #Merky FC HQ.</h4>
                            </> : <h4 className='account-sign-up-form-header-title'>update your information</h4>
                            }
                        </div>
                        <div className='account-sign-up-form-body'>
                            <div>
                                <div className='account-sign-up-form-body-item'>
                                    <input className='account-sign-up-form-body-item-input' type='text' name='firstName' placeholder='first name'
                                        {...register('firstName', { required: 'This field is required.' })} />
                                </div>
                                {
                                    (touchedFields.firstName && errors.firstName) && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                        {errors.firstName.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='account-sign-up-form-body-item'>
                                    <input className='account-sign-up-form-body-item-input' type='text' name='lastName' placeholder='last name'
                                        {...register('lastName', { required: 'This field is required.' })} />
                                </div>
                                {
                                    (touchedFields.lastName && errors.lastName) && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                        {errors.lastName.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='account-sign-up-form-body-item'>
                                    <input className='account-sign-up-form-body-item-input' type='text' name='phoneNumber' placeholder='contact number'
                                        {...register('phoneNumber', {
                                            required: 'Contact Number is required.',
                                            pattern: {
                                                value: Regexp._MOBILE_NUMBER,
                                                message: 'Please enter valid contact number.'
                                            }
                                        })} />
                                </div>
                                {
                                    errors.phoneNumber && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                        {errors.phoneNumber.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='account-sign-up-form-body-item'>
                                    <input className='account-sign-up-form-body-item-input' type='text' name='email' placeholder='email'
                                        {...register('email', {
                                            required: 'This field is required.',
                                            pattern: {
                                                value: Regexp._EMAIL,
                                                message: 'Please enter a valid e-mail address.'
                                            }
                                        })} />
                                </div>
                                {
                                    (touchedFields.email && errors.email) && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                        {errors.email.message}
                                    </h4>)
                                }
                            </div>
                            { state?.isForUpdate ?
                                <>
                                    <div>
                                        <div className='account-sign-up-form-body-item'>
                                            <input className='account-sign-up-form-body-item-input' type='password' name='currentPassword' placeholder='current password'
                                                {...register('currentPassword', {
                                                    // required: 'This field is required.',
                                                    pattern: {
                                                        value: Regexp._PASSWORD,
                                                        message: 'Current password must include 8 characters and must contain at least one uppercase character and at least one digit.'
                                                    }
                                                })} />
                                        </div>
                                        {
                                            (touchedFields.currentPassword && errors.currentPassword) && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                                {errors.currentPassword.message}
                                            </h4>)
                                        }
                                    </div>

                                    <div>
                                        <div className='account-sign-up-form-body-item'>
                                            <input className='account-sign-up-form-body-item-input' type='password' name='password' placeholder='new password'
                                                {...register('password', {
                                                    required: currentPasswordWatch ? 'This field is required.' : false,
                                                    pattern: {
                                                        value: Regexp._PASSWORD,
                                                        message: 'Password must include 8 characters and must contain at least one uppercase character and at least one digit.'
                                                    }
                                                })} />
                                        </div>
                                        {
                                            (touchedFields.password && errors.password) && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                                {errors.password.message}
                                            </h4>)
                                        }
                                    </div>

                                    <div>
                                        <div className='account-sign-up-form-body-item'>
                                            <input className='account-sign-up-form-body-item-input' type='password' name='reEnterPassword' placeholder='re-enter new password'
                                                onPaste={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                                {...register('reEnterPassword', {
                                                    required: currentPasswordWatch ? 'This field is required.' : false,
                                                    validate: (value) => value === passwordWatch || 'Password does not match.'
                                                })} />
                                        </div>
                                        {
                                            (touchedFields.reEnterPassword && errors.reEnterPassword) && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                                {errors.reEnterPassword.message}
                                            </h4>)
                                        }
                                    </div>
                                </> :
                                <>
                                    <div>
                                        <div className='account-sign-up-form-body-item'>
                                            <input className='account-sign-up-form-body-item-input' type='password' name='password' placeholder='password'
                                                {...register('password', {
                                                    required: 'This field is required.',
                                                    pattern: {
                                                        value: Regexp._PASSWORD,
                                                        message: 'Password must include 8 characters and must contain at least one uppercase character and at least one digit.'
                                                    }
                                                })} />
                                        </div>
                                        {
                                            (touchedFields.password && errors.password) && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                                {errors.password.message}
                                            </h4>)
                                        }
                                    </div>

                                    <div>
                                        <div className='account-sign-up-form-body-item'>
                                            <input className='account-sign-up-form-body-item-input' type='password' name='reEnterPassword' placeholder='re-enter password'
                                                onPaste={(e) => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                                {...register('reEnterPassword', {
                                                    required: 'This field is required.',
                                                    validate: (value) => value === passwordWatch || 'Password does not match.'
                                                })} />
                                        </div>
                                        {
                                            (touchedFields.reEnterPassword && errors.reEnterPassword) && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                                {errors.reEnterPassword.message}
                                            </h4>)
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        <h4 className='account-sign-up-form-note'>* Password must include 8 characters, uppercase, lowercase letters and one number</h4>
                    </div>

                    <div className='account-sign-up-tcs-container'>
                        <div className='account-sign-up-tcs-wrapper'>
                            <div className='account-sign-up-tcs-checkbox-wrapper'>
                                <label htmlFor='tAndCs'>
                                    {getValues('tAndCs') ?
                                        <svg className='account-sign-up-tcs-checkbox-icon' width="34" height="34" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Group">
                                                <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                                <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                            </g>
                                        </svg> :
                                        <svg className='account-sign-up-tcs-checkbox-icon' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <rect width="34" height="34" transform="translate(0 0)" fill="black" />
                                                <g id="Group 131">
                                                    <path id="Vector" d="M27.7592 0H6.25409L0 6.25409V27.7592L6.25409 34H27.7592L34 27.7592V6.25409L27.7592 0ZM8.34762 28.9119L5.08808 25.6524V8.34762L8.34762 5.08808H25.6391L28.8987 8.34762V25.6391L25.6391 28.8987H8.34762V28.9119Z" fill="#ffffff" />
                                                </g>
                                            </g>
                                        </svg>
                                    }
                                </label>
                                <input className='account-sign-up-tcs-checkbox-input' type='checkbox' {...register('tAndCs', { required: 'Please read and agree the Privacy Policy to proceed.' })} name='tAndCs' id='tAndCs' />
                            </div>
                            <h4 className='account-sign-up-tcs-label'>i’ve read and agree with the <a className='account-sign-up-tcs-link' href='/terms-and-conditions' target='_blank'>TERMS & CONDITIONS</a></h4>
                        </div>
                        {
                            errors.tAndCs && (<h4 className='account-sign-up-form-body-item-err-msg'>
                                {errors.tAndCs.message}
                            </h4>)
                        }
                    </div>

                    <div className='account-sign-up-action-wrapper'>
                        <div className='account-sign-up-action'>
                            <button className={'account-sign-up-action-btn' + ((isRequestAlive || Object.keys(errors).length) ? ' in-valid' : '')} type='submit' disabled={isRequestAlive || Object.keys(errors).length}>
                                {!state?.isForUpdate ? 'create account' : 'update'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AccountSignUp;