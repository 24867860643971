import React, { useEffect, useState } from 'react';

import './TermsAndConditions.component.scss';
import LOADER from '../../assets/images/LOADING_ICON.gif';

import { getTAndCsContent } from '../../services/privacy-policy-or-t-and-cs.service';


const TermsAndConditions = () => {

    const [termsAndConditions, setTermsAndConditions] = useState(null);
    const [isRequestAlive, setIsRequestAlive] = useState(true);

    useEffect(() => {
        try {
            setIsRequestAlive(true);
            getTAndCsContent().then((res) => {
                if (res.data.code === "OK") {
                    setTermsAndConditions(res.data.data);
                    setIsRequestAlive(false);
                }
            }).catch((err) => {
                setIsRequestAlive(false);
                console.log('Error while getting privacy policy', err);
            });
        } catch (error) {
            console.log('Error while getting privacy policy', error);
        }
    }, []);

    return (
        <>
            {!isRequestAlive && termsAndConditions &&
                <div className='terms-and-conditions-container'>

                    <div className='terms-and-conditions-head'>
                        <div className='terms-and-conditions-head-wrapper'>
                            <h4 className='terms-and-conditions-head-title'>terms and conditions</h4>
                            {termsAndConditions?.shortIntro && <h4 className='terms-and-conditions-head-description'>{termsAndConditions.shortIntro}</h4>}
                        </div>
                    </div>

                    <div className='terms-and-conditions-description' dangerouslySetInnerHTML={{ __html: termsAndConditions.content }}></div>
                </div>
            }
            {isRequestAlive &&
                <div className='terms-and-conditions-loader'>
                    <img className='terms-and-conditions-loader-img' src={LOADER} alt='loader' />
                </div>
            }
        </>
    )
}

export default TermsAndConditions;