import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './Booking.component.scss';
import crownStill from '../../assets/images/hash-still.png';

import AppContext from '../../context/app/app.context';
import { _bookingTypes } from '../../constants/booking-types';
import Regexp from '../../constants/regexp';
import { getBasketItems, addToBasket, getSlotList, removeToBasket } from '../../services/booking.service';
import { bookingSlotDate, bookingSlotDateMin } from '../../services/utils.service';
import { isUserLoggedIn } from '../../services/user.service';
import { getOrder } from '../../services/order.service';
import { create } from '../../services/music-studio.service';


function Booking() {

    const _peakStartHour = 17, _peakStartMinute = 0;

    const { appConfig } = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [encodeKey, setEncodeKey] = useState();
    const [selectedBookingType, setSelectedBookingType] = useState(location.state || 'pitch');

    const [selectedBooking, setSelectedBooking] = useState();
    const [gridLayoutSetting, setGridLayoutSetting] = useState({
        gridSize: 0,
        isWithBasket: true
    });
    const [togglePitchFilter, setTogglePitchFilter] = useState(false);
    const [pitchFilterList] = useState([
        {
            name: 'full_pitch',
            label: 'full pitch'
        },
        {
            name: 'half_pitch',
            label: 'half pitch'
        },
        {
            name: 'third_pitch',
            label: 'third pitch'
        }
    ]);
    const [selectedPitchType, setSelectedPitchType] = useState(pitchFilterList[0].name);
    const [slotList, setSlotList] = useState([]);
    const [slotConfig, setSlotConfig] = useState();
    const [myBasket, setMyBasket] = useState({ list: [], total: 0 });
    const [isBasketMViewShow, setIsBasketMViewShow] = useState(false);


    const musicStudioDefaultValues = {
        firstName: '',
        lastName: '',
        contactNumber: '',
        email: '',
        date: '',
        time: '',
        query: ''
    };
    const [isRequestAlive, setIsRequestAlive] = useState(false);
    const [musicStudioFormSubmitted, setMusicStudioFormSubmitted] = useState(null);
    const { register, handleSubmit, formState: { errors, isDirty, isValid }, getValues, reset } = useForm({
        mode: 'all',
        defaultValues: musicStudioDefaultValues
    });


    /** component will-mount */
    useEffect(() => {
        window.scrollTo(0, 0);
        const bookingObj = _bookingTypes.find((item) => item.type === selectedBookingType);
        setSelectedBooking(bookingObj);
        setEncodeKey(`${window.screen.height}${window.screen.width}${window.screen.colorDepth}${new Date().getTime()}`);
        // eslint-disable-next-line
    }, []);

    const resizeCallback = () => {
        /** for slots and basket layout
         * 1-Grid with screen size 0px to 679px, without basket
         * */
        setGridLayoutSetting((previousValue) => {
            if (window.innerWidth <= 679) {
                if (previousValue.gridSize !== 1 || previousValue.isWithBasket !== false) {
                    previousValue = { gridSize: 1, isWithBasket: false };
                }
                /** 2-Grid with screen size between 680px to 768px, without basket */
            } else if (window.innerWidth >= 680 && window.innerWidth <= 768) {
                if (previousValue.gridSize !== 2 || previousValue.isWithBasket !== false) {
                    previousValue = { gridSize: 2, isWithBasket: false };
                }
                /** 1-Grid with screen size between 769px to 1134px, with basket */
            } else if (window.innerWidth >= 769 && window.innerWidth <= 1134) {
                if (previousValue.gridSize !== 1 || previousValue.isWithBasket !== true) {
                    previousValue = { gridSize: 1, isWithBasket: true };
                }
                /** 2-Grid with screen size between 1135px to 1429px, with basket */
            } else if (window.innerWidth >= 1135 && window.innerWidth <= 1429) {
                if (previousValue.gridSize !== 2 || previousValue.isWithBasket !== true) {
                    previousValue = { gridSize: 2, isWithBasket: true };
                }
                /** 3-Grid with screen size 1430px to up, with basket */
            } else {
                if (previousValue.gridSize !== 3 || previousValue.isWithBasket !== true) {
                    previousValue = { gridSize: 3, isWithBasket: true };
                }
            }
            return previousValue;
        });

        /** for pitch filter */
        setTogglePitchFilter((PSTogglePitchFilter) => {
            if (PSTogglePitchFilter) {
                /** 992px it is based on break point of min to max devices */
                if (window.innerWidth < 992) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }
            }
            return PSTogglePitchFilter;
        });
    }

    useEffect(() => {
        window.addEventListener('resize', resizeCallback);
        resizeCallback();
        /** this return callback function executes at when current component is unload from DOM. */
        return () => {
            window.removeEventListener('resize', resizeCallback);
        }
        // eslint-disable-next-line
    }, []);


    const APISetTimeout = useRef();
    const previousGridSize = useRef();
    useEffect(() => {
        if (slotList.length < gridLayoutSetting.gridSize) {
            /** API Call */ // console.log("API Call", gridLayoutSetting);
            previousGridSize.current = gridLayoutSetting.gridSize;
            resizeCallbackEnd();
        } else {
            if (gridLayoutSetting.gridSize !== 0 && gridLayoutSetting.gridSize !== previousGridSize.current) {
                /** Clearing the Timeout */
                clearTimeout(APISetTimeout.current);
                APISetTimeout.current = setTimeout(() => {
                    previousGridSize.current = gridLayoutSetting.gridSize;
                    resizeCallbackEnd();
                }, 1000);
            } else {
                previousGridSize.current = gridLayoutSetting.gridSize;
            }
        }
        // eslint-disable-next-line
    }, [gridLayoutSetting, togglePitchFilter]);

    /** we are getting all basket items if added in basket or if item(s) is/are available in server */
    useEffect(() => {
        if (!isUserLoggedIn() && !localStorage.getItem('sessionId')) return;

        getBasketItems().then((res) => {
            if (res.data.code === "OK") {
                const response = res.data.data;
                if (response?.list?.length) {
                    const list = [];
                    for (const item of response.list) {
                        list.push({
                            ...item,
                            previewDate: bookingSlotDate(item.date),
                            // name: item.type.replace('_', ' ').replace(/\b\w/g, (match) => match.toUpperCase())
                        });
                    }
                    myBasket.list = list;
                    myBasket.total = response.totalAmount;
                    setMyBasket({ ...myBasket });
                }
            }
        }).catch((err) => {
            console.log('Error while getting basket items', err);
        });
        // eslint-disable-next-line
    }, []);

    const getAndSetSlotList = (body, pitchTypeAdded = false) => {
        if (body.assetType === "pitch" && !pitchTypeAdded) {
            body.pitchType = selectedPitchType.replace('_pitch', '').toUpperCase();
        }
        // console.log(body);
        getSlotList(body, encodeKey)
            .then((res) => {
                if (res.data.code === "OK") {
                    const response = res.data.data;
                    let priceType = 'full';
                    if (body.assetType === 'pitch') {
                        // pitchType come with on pitch selection change
                        priceType = body.pitchType?.toLowerCase();
                    }

                    setSlotConfig({ ...response.slotConfig });
                    const list = response.list ?? [];
                    for (const item of list) {
                        item.dDate = bookingSlotDateMin(item.date);
                        for (const slot of item.slot) {
                            // 0 sunday, 6 saturday
                            if ([0, 6].includes(new Date(item.date).getDay())) {
                                slot.price = response.slotConfig.pricing.peakPrice[priceType];
                            } else {
                                const slotStartTime = new Date(item.date);
                                slotStartTime.setHours(slot.startHs, slot.startMs, 0, 0);
                                const peakStartTime = new Date(item.date);
                                peakStartTime.setHours(_peakStartHour, _peakStartMinute, 0, 0);

                                if (slotStartTime.getTime() < peakStartTime.getTime()) {
                                    slot.price = response.slotConfig.pricing.offPeakPrice[priceType];
                                } else {
                                    slot.price = response.slotConfig.pricing.peakPrice[priceType];
                                }
                            }
                        }
                    }

                    setSlotList([...list]);
                }
            }).catch((error) => {
                console.log('Error while getting booking slots', error);
            });
    }

    const resetInputFieldValues = () => {
        document.querySelectorAll('[name^="slotLimit-"]').forEach((element) => {
            if (parseInt(element.value) > 1) {
                element.value = '01';
            }
        });
    };

    const onChangeBookingCategory = (category) => {
        if (category && selectedBookingType !== category) {
            const bookingObj = _bookingTypes.find((item) => item.type === category);
            setSelectedBooking(bookingObj);
            setSelectedBookingType(category);
            slotBoundary.isPreviousSlotBtnDisabled = true;
            setSlotBoundary({ ...slotBoundary });

            if (['music_studio', 'games_room'].includes(category)) {
                resetInputFieldValues();
            }

            const body = {
                assetType: category,
                date: new Date().toISOString(),
                slotSize: gridLayoutSetting.gridSize
            };

            if (category === "pitch") {
                body.pitchType = selectedPitchType.replace('_pitch', '').toUpperCase();
                const slotStartDate = new Date(slotList[0].date);
                slotStartDate.setUTCHours(0, 0, 0, 0);
                body.date = slotStartDate.toISOString();
            }

            getAndSetSlotList(body, true);
        }
    };

    const resizeCallbackEnd = () => {
        // console.log(gridLayoutSetting);

        const body = {
            assetType: selectedBookingType,
            date: new Date().toISOString(),
            slotSize: gridLayoutSetting.gridSize
        };

        if (slotList.length) {
            if (gridLayoutSetting.gridSize < slotList.length) {
                setSlotList([...slotList.splice(0, gridLayoutSetting.gridSize)]);
                return;
            } else if (gridLayoutSetting.gridSize <= slotList.length) {
                return;
            }
            const slotStartDate = new Date(slotList[0].date);
            slotStartDate.setUTCHours(0, 0, 0, 0);
            body.date = slotStartDate.toISOString();
        }

        getAndSetSlotList(body);
    };

    const onTogglePitchFilter = () => {
        if (!togglePitchFilter) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        setTogglePitchFilter(!togglePitchFilter);
    };

    const onPitchFilterChange = (itemIndex) => {
        const currentSelection = pitchFilterList[itemIndex].name;
        if (selectedPitchType === currentSelection) return;
        setSelectedPitchType(currentSelection);

        const body = {
            assetType: selectedBookingType,
            date: new Date().toISOString(),
            slotSize: gridLayoutSetting.gridSize
        };

        if (selectedBookingType === "pitch") {
            body.pitchType = currentSelection.replace('_pitch', '').toUpperCase();
            const slotStartDate = new Date(slotList[0].date);
            slotStartDate.setUTCHours(0, 0, 0, 0);
            body.date = slotStartDate.toISOString();
        }

        getAndSetSlotList(body, true);
    };

    const [slotBoundary, setSlotBoundary] = useState({ isPreviousSlotBtnDisabled: true, isNextSlotBtnDisabled: false });
    const onPreviousGetSlot = () => {

        const previousDate = new Date(slotList[0].date);
        previousDate.setUTCHours(0, 0, 0, 0);
        previousDate.setUTCDate(previousDate.getUTCDate() - 1);
        const toDayDate = new Date(new Date().setUTCHours(0, 0, 0, 0));

        if (previousDate.getTime() < toDayDate.getTime()) {
            slotBoundary.isPreviousSlotBtnDisabled = true;
            setSlotBoundary({ ...slotBoundary });
            return;
        } else if (previousDate.getTime() === toDayDate.getTime()) {
            slotBoundary.isPreviousSlotBtnDisabled = true;
            setSlotBoundary({ ...slotBoundary });
        } else if (slotBoundary.isPreviousSlotBtnDisabled) {
            slotBoundary.isPreviousSlotBtnDisabled = false;
            setSlotBoundary({ ...slotBoundary });
        }

        if (slotBoundary.isNextSlotBtnDisabled) {
            slotBoundary.isNextSlotBtnDisabled = false;
            setSlotBoundary({ ...slotBoundary });
        }

        const body = {
            assetType: selectedBookingType,
            date: new Date(previousDate.getTime()).toISOString(),
            slotSize: gridLayoutSetting.gridSize
        };
        getAndSetSlotList(body);
    };

    const onNextGetSlot = () => {

        if (slotBoundary.isPreviousSlotBtnDisabled) {
            slotBoundary.isPreviousSlotBtnDisabled = false;
            setSlotBoundary({ ...slotBoundary });
        }
        const nextDate = new Date(slotList[0].date);
        nextDate.setUTCHours(0, 0, 0, 0);
        nextDate.setUTCDate(nextDate.getUTCDate() + 1);

        if (appConfig?.bookingDaysLimit) {
            const lastDate = new Date();
            lastDate.setUTCDate((lastDate.getUTCDate() + parseInt(appConfig?.bookingDaysLimit)) - gridLayoutSetting.gridSize);
            lastDate.setUTCHours(0, 0, 0, 0);
            if (nextDate.getTime() === lastDate.getTime()) {
                slotBoundary.isNextSlotBtnDisabled = true;
                setSlotBoundary({ ...slotBoundary });
            }
            if (nextDate.getTime() > lastDate.getTime()) {
                return;
            }
        }

        const body = {
            assetType: selectedBookingType,
            date: new Date(nextDate.getTime()).toISOString(),
            slotSize: gridLayoutSetting.gridSize
        };

        getAndSetSlotList(body);
    };

    const [isAddToBasketRequestAlive, setIsAddToBasketRequestAlive] = useState(false);
    const onAddToBasket = (slotIndex, slotItemIndex) => {
        // if (!isUserLoggedIn() || slotList[slotIndex]?.slot[slotItemIndex]?.isBooked || isAddToBasketRequestAlive || selectedBookingType === 'music_studio') return;
        if (slotList[slotIndex]?.slot[slotItemIndex]?.isBooked || isAddToBasketRequestAlive || selectedBookingType === 'music_studio') return;

        const { isBooked, availableConsoles, ...slotDetails } = slotList[slotIndex].slot[slotItemIndex];
        slotDetails.bookingType = selectedBookingType;
        slotDetails.date = slotList[slotIndex].date?.split('T')[0];
        if (selectedBookingType === 'pitch') {
            slotDetails.pitchType = selectedPitchType.replace('_pitch', '').toUpperCase();
        }

        let peopleAndConsoleCount = 0;
        if (selectedBookingType === 'music_studio' || selectedBookingType === 'games_room') {
            const qtyInput = document.getElementsByName(`slotLimit-${slotIndex}-${slotItemIndex}`);
            peopleAndConsoleCount = parseInt(qtyInput[0]?.value || 1);
            qtyInput[0].value = "01";
            if (selectedBookingType === 'games_room') {
                slotDetails.consoleCount = peopleAndConsoleCount;
                document.getElementById(`console-label-${slotIndex}-${slotItemIndex}`).innerText = 'Console \u00A0';
            } else if (selectedBookingType === 'music_studio') {
                slotDetails.peopleCount = peopleAndConsoleCount;
            }
        }

        setIsAddToBasketRequestAlive(true);
        /** call an API for book the slot, if request response is OK then block the slot */
        addToBasket(slotDetails).then((res) => {
            if (res.headers.has('Session-Id')) {
                localStorage.setItem('sessionId', res.headers.get('Session-Id'));
            }

            if (res.data.code === "OK") {

                const response = res.data.data;
                if (response?.isSlotBlocked) {
                    slotList[slotIndex].slot[slotItemIndex].isBooked = true;
                    setSlotList([...slotList]);
                }

                if (response?.isSlotBooked) {
                    const slot = slotList[slotIndex].slot[slotItemIndex];
                    const basketItem = {
                        date: slotList[slotIndex].date,
                        previewDate: bookingSlotDate(slotList[slotIndex].date),
                        endHs: slot.endHs,
                        endMs: slot.endMs,
                        startHs: slot.startHs,
                        startMs: slot.startMs,
                        name: selectedBookingType.replace('_', ' ').replace(/\b\w/g, (match) => match.toUpperCase()),
                        type: selectedBookingType.toUpperCase(),
                        price: 0
                    };

                    if (selectedBookingType === 'pitch') {

                        basketItem.name = selectedPitchType.replace('_', ' ').replace(/\b\w/g, (match) => match.toUpperCase());
                        basketItem.type = selectedPitchType.replace('_pitch', '').toUpperCase();
                        basketItem.price = parseFloat(slot.price?.toFixed(2));
                        myBasket.list.push(basketItem);
                        myBasket.total = myBasket.total + slot.price;

                    } else if (selectedBookingType === 'music_studio') {

                        basketItem.price = parseFloat(slot.price?.toFixed(2));
                        basketItem.peopleCount = peopleAndConsoleCount;
                        myBasket.list.push(basketItem);
                        myBasket.total = myBasket.total + slot.price;

                    } else if (selectedBookingType === 'games_room') {

                        const basketItemIndex = myBasket.list.findIndex((item) => item.type === basketItem.type && item.date === basketItem.date && item.startHs === basketItem.startHs && item.startMs === basketItem.startMs);
                        if (basketItemIndex === -1) {
                            basketItem.price = parseFloat(slot.price?.toFixed(2));
                            basketItem.consoleCount = peopleAndConsoleCount;
                            myBasket.list.push(basketItem);
                        } else {
                            myBasket.list[basketItemIndex].consoleCount = parseInt(myBasket.list[basketItemIndex].consoleCount + peopleAndConsoleCount);
                        }
                        myBasket.total = myBasket.total + (peopleAndConsoleCount * slot.price);

                        if ('availableConsoles' in response) {
                            slotList[slotIndex].slot[slotItemIndex].availableConsoles = Math.max(0, response?.availableConsoles);
                            setSlotList([...slotList]);
                        }
                    }

                    myBasket.total = parseFloat(myBasket.total?.toFixed(2));
                    setMyBasket({ ...myBasket });
                }
            }
            setIsAddToBasketRequestAlive(false);
        }).catch((err) => {
            setIsAddToBasketRequestAlive(false);
            console.log('Error while add item in basket', err);
        });
    };

    const onMaxLimitPerSlotBlur = (e, availableConsoleCount = 0) => {
        const slotSize = parseInt(e.target?.value);
        if (!availableConsoleCount) {
            if (slotSize <= 0) {
                e.target.value = "01";
            } else if (slotSize > slotConfig.maxLimitPerSlot) {
                e.target.value = slotConfig.maxLimitPerSlot <= 9 ? `0${slotConfig.maxLimitPerSlot}` : slotConfig.maxLimitPerSlot;
            } else {
                e.target.value = slotSize <= 9 ? `0${slotSize}` : slotSize;
            }
        } else {
            if (slotSize <= 0) {
                e.target.value = "01";
            } else if (slotSize > availableConsoleCount) {
                e.target.value = availableConsoleCount <= 9 ? `0${availableConsoleCount}` : availableConsoleCount;
            } else {
                e.target.value = slotSize <= 9 ? `0${slotSize}` : slotSize;
            }
        }
    }

    const onChangeForInputPrefix = (e, elId) => {
        if (elId?.startsWith('people-label')) {
            document.getElementById(elId).innerText = `People${+e.target.value > 1 ? 's' : ''} \u00A0`;
        } else {
            document.getElementById(elId).innerText = `console${+e.target.value > 1 ? 's' : ''} \u00A0`;
        }
    }

    const [isRemoveToBasketRequestAlive, setIsRemoveToBasketRequestAlive] = useState(false);
    const onRemoveToBasket = (basketItemIndex) => {
        // if (isRemoveToBasketRequestAlive || !isUserLoggedIn()) return;
        if (isRemoveToBasketRequestAlive) return;

        const basketItem = myBasket.list[basketItemIndex];
        const body = {
            basketItems: [{
                date: basketItem.date,
                startHs: basketItem.startHs,
                startMs: basketItem.startMs,
                type: basketItem.type
            }]
        };
        if (selectedBookingType === 'pitch') {
            body.bookingType = selectedPitchType.replace('_pitch', '').toUpperCase();
        }
        if (basketItem.type === "GAMES_ROOM") {
            body.basketItems[0].consoleCount = basketItem.consoleCount;
        }

        setIsRemoveToBasketRequestAlive(true);
        removeToBasket(body).then((res) => {
            if (res.data.code === "OK") {
                const response = res.data.data;

                if (['FULL', 'HALF', 'THIRD', 'MUSIC_STUDIO'].includes(basketItem.type)) {

                    if ('isSlotBooked' in response) {
                        myBasket.total = parseFloat((myBasket.total - basketItem.price)?.toFixed(2));
                        myBasket.list.splice(basketItemIndex, 1);
                        setMyBasket({ ...myBasket });
                        validateSlotList(response, basketItem);
                    }

                } else if ('GAMES_ROOM' === basketItem.type) {

                    if ('availableConsoles' in response && 'isSlotBooked' in response) {
                        myBasket.total = parseFloat((myBasket.total - (basketItem.price * basketItem.consoleCount))?.toFixed(2));
                        myBasket.list.splice(basketItemIndex, 1);
                        setMyBasket({ ...myBasket });
                        validateSlotList(response, basketItem);
                    }
                }
            }
            setIsRemoveToBasketRequestAlive(false);
        }).catch((err) => {
            setIsRemoveToBasketRequestAlive(false);
            console.log('Error while remove basket item', err);
        });
    }

    const validateSlotList = (response, basketItem) => {
        if (selectedBookingType !== 'pitch' && selectedBookingType?.toUpperCase() !== basketItem.type) return;
        const modifiedList = slotList.map((slots) => {
            if (slots.date === basketItem.date) {
                slots.slot.map((item) => {
                    if (item.startHs === basketItem.startHs && item.startMs === basketItem.startMs) {
                        item.isBooked = response.isSlotBooked;
                        if (basketItem.type === 'GAMES_ROOM' && selectedBookingType === 'games_room') {
                            item.availableConsoles = response.availableConsoles;
                        }
                    }
                    return item;
                });
            }
            return slots;
        });
        // console.log(modifiedList);
        setSlotList([...modifiedList]);
    }

    // const removeBasketOnUnload = () => {
    //     if (isRemoveToBasketRequestAlive || !isUserLoggedIn() || !myBasket.list?.length) return;

    //     const basketItems = myBasket.list.map((item) => {
    //         return {
    //             date: item.date,
    //             startHs: item.startHs,
    //             startMs: item.startMs,
    //             type: item.type
    //         };
    //     });

    //     setIsRemoveToBasketRequestAlive(true);
    //     removeToBasket({ basketItems }).then((res) => {
    //         if (res.data.code === "OK") {
    //             // const response = res.data.data;
    //             // console.log(response);
    //             setIsRemoveToBasketRequestAlive(false);
    //         }
    //     }).catch((err) => {
    //         setIsRemoveToBasketRequestAlive(false);
    //         console.log('Error while remove basket item', err);
    //     });
    // }

    // const isCheckoutClicked = useRef(false);
    /** component did-mount runs at when component unload in DOM
     * remove the user's selected all items in server booking queue and release the blocked slots
    */
    // useEffect(() => {
    //     return () => {
    //         if (!isCheckoutClicked.current) {
    //             removeBasketOnUnload();
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, []);

    const [isCheckoutRequestAlive, setIsCheckoutRequestAlive] = useState(false);
    const onCheckout = () => {
        if (isCheckoutRequestAlive || (!isUserLoggedIn() && !localStorage.getItem('sessionId')) || !myBasket.list?.length) return;

        const body = myBasket.list.map((item) => ({
            date: item.date,
            type: item.type,
            startHs: item.startHs,
            startMs: item.startMs
        }));

        setIsCheckoutRequestAlive(true);
        getOrder(body).then((res) => {
            if (res.data.code === 'OK') {
                const response = res.data.data;
                if (response?.clientSecret) {
                    // isCheckoutClicked.current = true;
                    navigate('/checkout', {
                        state: { ...response }
                    });
                } else if (response?.blockedSlots?.length) {
                    myBasket.list = myBasket.list.filter((item) => {
                        return !response?.blockedSlots.some((blockedSlot) => {
                            return item.date === blockedSlot.date && item.type === blockedSlot.type && item.startHs === blockedSlot.startHs && item.startMs === blockedSlot.startMs;
                        });
                    });

                    myBasket.total = 0;
                    for (const basketItem of myBasket.list) {
                        if (['FULL', 'HALF', 'THIRD'].includes(basketItem.type)) {
                            if ('isSlotBooked' in response) {
                                myBasket.total += basketItem.price;
                            }
                        } else if ('GAMES_ROOM' === basketItem.type) {
                            myBasket.total += (basketItem.price * basketItem.consoleCount);
                        }
                    }
                    myBasket.total = parseFloat(myBasket.total?.toFixed(2));
                    setMyBasket({ ...myBasket });
                }
            }
            setIsCheckoutRequestAlive(false);
        }).catch((err) => {
            setIsCheckoutRequestAlive(false);
            console.log('Error while getting order for checkout', err);
        });
    }


    const onSubmit = (data, e) => {
        e.preventDefault();
        try {
            if (!isValid || !isDirty || isRequestAlive) {
                return;
            } else {
                setIsRequestAlive(true);
                create(data).then((res) => {
                    reset(musicStudioDefaultValues);
                    setMusicStudioFormSubmitted(setTimeout(() => {
                        setMusicStudioFormSubmitted(null);
                    }, 8000));
                    setIsRequestAlive(false);
                }).catch(err => {
                    setIsRequestAlive(false);
                    console.log('Error while creating music studio enquiry', err.error);
                });
            }
        } catch (error) {
            setIsRequestAlive(false);
            console.log(`Error while register user`, error);
        }
    }

    const onCloseMSFormSubmit = () => {
        if (musicStudioFormSubmitted) {
            clearTimeout(musicStudioFormSubmitted);
            setMusicStudioFormSubmitted(null);
        }
    }

    return (
        <div className="booking-container">

            <div className='booking-hero-wrapper'>
                <picture>
                    {selectedBooking?.heroImage?.list?.map((sourceItem, i) => {
                        return <source key={i} media={sourceItem?.media} srcSet={sourceItem?.srcset} />
                    })}
                    <img className='booking-hero-img' srcSet={selectedBooking?.heroImage?.default} draggable="false" alt="Chris standing up holding his daughter Elva" />
                </picture>
            </div>

            <div className={'booking-selection-container' + (selectedBookingType === "music_studio" ? ' border-0' : '')}>
                <div className='booking-selection-wrapper'>
                    <h4 className='booking-selection-types-title'>
                        We have amazing facilities to offer at #Merky FC HQ including our new EA Sports Gaming Hub and state-of-the-art Music Studio. We have a new 3G astroturf pitch that can be booked  full size, half and a third, perfect for 11 v 11 and 7v7 games.
                    </h4>
                    <div className='booking-selection-types-wrapper'>
                        <div className={'booking-selection-type' + (selectedBookingType === 'pitch' ? ' selected' : '')} onClick={() => { onChangeBookingCategory('pitch') }}>pitch</div>
                        <div className={'booking-selection-type' + (selectedBookingType === 'music_studio' ? ' selected' : '')} onClick={() => { onChangeBookingCategory('music_studio') }}>music studio</div>
                        <div className={'booking-selection-type' + (selectedBookingType === 'games_room' ? ' selected' : '')} onClick={() => { onChangeBookingCategory('games_room') }}>games room</div>
                    </div>
                </div>

                <div className='booking-title-desc-wrapper'>
                    <h1 className='booking-title'>{selectedBooking && selectedBooking.bookingTitle}</h1>
                    <h4 className='booking-description'>{selectedBooking && selectedBooking.description}</h4>
                    <div className='crown-still'>
                        <img src={crownStill} draggable="false" alt='' />
                    </div>
                </div>

                <div className={'booking-pitch-filter-container' + (selectedBookingType === 'pitch' ? ' show' : '')}>
                    <div className='booking-pitch-filter-wrapper'>
                        <h4 className='booking-pitch-filter-title'>filter pitch by:</h4>

                        {
                            pitchFilterList.map((pitchFilterItem, i) => {
                                return (
                                    <div className='booking-pitch-filter-item-wrapper' key={i}>
                                        <input className='booking-pitch-filter-item' type='radio' name='pitchFilter' checked={selectedPitchType === pitchFilterItem.name} value={pitchFilterItem.name} onChange={() => { }} />
                                        <label htmlFor='halfPitch'>
                                            <h4 className='booking-pitch-filter-item-label' onClick={() => onPitchFilterChange(i)}>{pitchFilterItem.label}</h4>
                                        </label>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <div className='booking-pitch-filter-m-container'>
                        <div className='booking-pitch-filter-m-btn-wrapper'>
                            <button type='button' onClick={() => onTogglePitchFilter()} className='booking-pitch-filter-m-btn'>filter pitch</button>
                        </div>
                    </div>
                </div>
                {
                    (!gridLayoutSetting.isWithBasket && selectedBookingType !== "music_studio") &&
                    <div className='booking-checkout-m-container'>
                        <div className={'booking-checkout-m-btn-wrapper' + (selectedBookingType !== 'pitch' ? ' adjust-padding' : '')}>
                            <button type='button' className='booking-checkout-m-btn' onClick={() => setIsBasketMViewShow(true)}>checkout</button>
                        </div>
                    </div>
                }
            </div>




            {/* booking and basket sections */}
            <div className='booking-section-container'>
                {selectedBookingType === "music_studio" ?
                    <>
                        <form className='music-studio-form-wrap' onSubmit={handleSubmit((data, e) => onSubmit(data, e))} autoComplete="off">
                            <div>
                                <div className='music-studio-form-item'>
                                    <input className='music-studio-form-item-input' type='text' name='firstName' placeholder='first name'
                                        {...register('firstName', { required: 'First Name is required.' })} />
                                </div>
                                {
                                    (errors.firstName) && (<h4 className='music-studio-form-item-err-msg'>
                                        {errors.firstName.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='music-studio-form-item'>
                                    <input className='music-studio-form-item-input' type='text' name='lastName' placeholder='last name'
                                        {...register('lastName', { required: 'Last Name is required.' })} />
                                </div>
                                {
                                    (errors.lastName) && (<h4 className='music-studio-form-item-err-msg'>
                                        {errors.lastName.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='music-studio-form-item'>
                                    <input className='music-studio-form-item-input' type='text' name='contactNumber' placeholder='contact number'
                                        {...register('contactNumber', {
                                            required: 'Contact Number is required.',
                                            pattern: {
                                                value: Regexp._MOBILE_NUMBER,
                                                message: 'Please enter valid contact number.'
                                            }
                                        })} />
                                </div>
                                {
                                    errors.contactNumber && (<h4 className='music-studio-form-item-err-msg'>
                                        {errors.contactNumber.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='music-studio-form-item'>
                                    <input className='music-studio-form-item-input' type='text' name='email' placeholder='email'
                                        {...register('email', {
                                            required: 'Email is required.',
                                            pattern: {
                                                value: Regexp._EMAIL,
                                                message: 'Please enter valid e-mail address.'
                                            },
                                            validate: (value) => (value?.length > 254 ? 'An email address must not exceed 254 characters.' : null)
                                        })} />
                                </div>
                                {
                                    (errors.email) && (<h4 className='music-studio-form-item-err-msg'>
                                        {errors.email.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='music-studio-form-item'>
                                    <input className={'music-studio-form-item-input date-time-icon' + (getValues('date') ? '' : ' date-time')} type='date' name='date'
                                        {...register('date', {
                                            required: 'Date is required.',
                                            validate: (value) => {
                                                const inputDate = new Date(new Date(value).setHours(0, 0, 0, 0));
                                                const todayDate = new Date(new Date().setHours(0, 0, 0, 0));
                                                return inputDate < todayDate ? 'Date must be feature date.' : true;
                                            }
                                        })} />
                                </div>
                                {
                                    (errors.date) && (<h4 className='music-studio-form-item-err-msg'>
                                        {errors.date.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='music-studio-form-item'>
                                    <input className={'music-studio-form-item-input date-time-icon' + (getValues('time') ? '' : ' date-time')} type='time' name='time'
                                        {...register('time', {
                                            required: 'Time is required.',
                                            validate: (value) => {
                                                const inputDateTime = new Date(new Date(getValues('date')).setHours(value.split(':')[0], value.split(':')[1], 0, 0));
                                                const todayDateTime = new Date(getValues('date'));
                                                return inputDateTime < todayDateTime ? `Time must be feature time.` : true;
                                            }
                                        })} />
                                </div>
                                {
                                    (errors.time) && (<h4 className='music-studio-form-item-err-msg'>
                                        {errors.time.message}
                                    </h4>)
                                }
                            </div>
                            <div>
                                <div className='music-studio-form-item multiline'>
                                    <textarea className='music-studio-form-item-input' name='query' placeholder='any other questions (optional)'
                                        {...register('query')}></textarea>
                                </div>
                            </div>

                            <div className='music-studio-form-action-wrapper'>
                                <button className='music-studio-form-action-btn' type='submit' disabled={isRequestAlive}>send enquiry</button>
                            </div>
                        </form>

                        {musicStudioFormSubmitted &&
                            <div className='music-studio-form-submitted-ctr'>
                                <div className='music-studio-form-submitted-wrapper'>
                                    <div className='music-studio-form-submitted-modal-close-btn' onClick={onCloseMSFormSubmit}>
                                        <svg viewBox="0 0 32 32" fill="none">
                                            <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#1E1E1E" />
                                        </svg>
                                    </div>

                                    <div className='music-studio-form-submitted-poster-wrapper'>
                                        <img className='music-studio-form-submitted-poster' draggable="false" alt='left poster' />
                                    </div>
                                    <div className='music-studio-form-submitted-section-wrapper'>
                                        <div className='music-studio-form-submitted-section'>
                                            <div>
                                                <h4 className='music-studio-form-submitted-section-title'>request submitted</h4>
                                                <p className='music-studio-form-submitted-section-description mb'>Thanks for requesting to book. We’ll be in touch soon.</p>
                                            </div>

                                            <div className='music-studio-form-submitted-section-action'>
                                                <button className='music-studio-form-submitted-section-submit-btn' type='button' onClick={onCloseMSFormSubmit}>close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <div className={'booking-section-wrapper' + (gridLayoutSetting.isWithBasket ? '' : ' m-view')}>

                        <div className='booking-slots-container'>
                            <div className='booking-slots-wrapper'>
                                {/* Previous button */}
                                <div className={'booking-slot-previous-btn' + (slotBoundary.isPreviousSlotBtnDisabled ? ' disabled' : '')} onClick={() => onPreviousGetSlot()}>
                                    <svg fill="none" viewBox="6 -1 32 32">
                                        <path d="M25.0542 19.825V21.085H24.1792L15.7792 15.905V14.155L24.2142 8.975H25.0542V10.235L17.2842 14.96V15.1L25.0542 19.825Z" fill="#B3B4B4"></path>
                                        <g clipPath="url(#clip0_1347_20751)">
                                            <path d="M31.1515 30.5H31.3586L31.505 30.3536L37.3536 24.505L37.5 24.3586V24.1515V5.84852V5.64142L37.3536 5.49497L31.505 -0.353553L31.3586 -0.5H31.1515H12.8485H12.6414L12.495 -0.353553L6.64645 5.49497L6.5 5.64142V5.84852V24.1515V24.3586L6.64645 24.505L12.495 30.3536L12.6414 30.5H12.8485H31.1515ZM35.5293 23.5452L30.5424 28.5321H13.4548L8.46792 23.5452V6.45756L13.4576 1.46792H30.5424L35.5293 6.45749V23.5452Z" fill="#B3B4B4" stroke="#B3B4B4"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1347_20751">
                                                <rect width="30" height="30" fill="white" transform="translate(7)"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>

                                {/* date wise grid based on width, grid like small screen to large  1,  1 2,  1 2 3  */}
                                <div className={'booking-slots' + (gridLayoutSetting.gridSize !== 3 ? ` size-${gridLayoutSetting.gridSize}` : '')}>
                                    {
                                        slotList?.map((slotItem, i) => {
                                            return (
                                                <div className='booking-slot-wrapper' key={i}>
                                                    <div className='booking-slot-title'>{slotItem?.dDate}</div>
                                                    <div className='booking-slot-list'>
                                                        {slotItem?.slot?.map((slot, ii) => {
                                                            return (
                                                                <div className={'booking-slot-details' + (slot?.isBooked ? ' booked' : '')} key={`${i},${ii}`}>
                                                                    <h4 className='booking-slot-detail-title'>{`${slot.startHs}:${slot.startMs}-${slot.endHs}:${slot.endMs}`}</h4>

                                                                    {slot?.isBooked ?
                                                                        <>{/* for booked state, just for display the information to the user  */}
                                                                            <div className='booking-slot-detail-input-wrapper'>
                                                                                {selectedBookingType === 'pitch' ?
                                                                                    `${selectedPitchType.replace('_', ' ')} \u00A0|\u00A0 £${slot.price}`
                                                                                    :
                                                                                    <>
                                                                                        {`${selectedBookingType === 'music_studio' ? 'people' : 'console'} \u00A0`}
                                                                                        <input className='booking-slot-detail-input-field' type='number' defaultValue={'01'} readOnly />{`\u00A0 |\u00A0 £${slot.price}`}
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            <div className='booking-slot-detail-add-link'>not available</div>
                                                                        </> :
                                                                        <>{/* for available state */}
                                                                            <div className='booking-slot-detail-input-wrapper'>
                                                                                {selectedBookingType === 'pitch' ?
                                                                                    `${selectedPitchType.replace('_', ' ')} \u00A0|\u00A0 £${slot.price}`
                                                                                    :
                                                                                    selectedBookingType === 'music_studio' ?
                                                                                        <>
                                                                                            <span id={`people-label-${i}-${ii}`}>people &nbsp;</span><input className='booking-slot-detail-input-field' type='number' name={`slotLimit-${i}-${ii}`} defaultValue={'01'} min={1} max={slotConfig?.maxLimitPerSlot} onBlur={(e) => onMaxLimitPerSlotBlur(e)} />{`\u00A0 |\u00A0 £${slot.price}`}
                                                                                            {/* onChange={(e) => onChangeForInputPrefix(e, `people-label-${i}-${ii}`)}  */}
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <span id={`console-label-${i}-${ii}`}>console &nbsp;</span><input className='booking-slot-detail-input-field' type='number' name={`slotLimit-${i}-${ii}`} defaultValue={'01'} min={1} max={slot?.availableConsoles || slotConfig?.maxLimitPerSlot} onBlur={(e) => onMaxLimitPerSlotBlur(e, slot?.availableConsoles)}
                                                                                                onChange={(e) => onChangeForInputPrefix(e, `console-label-${i}-${ii}`)} />{`\u00A0 |\u00A0 £${slot.price}`}
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                            <div className='booking-slot-detail-add-link' onClick={() => onAddToBasket(i, ii)}>
                                                                                add to basket
                                                                            </div>
                                                                            {/* {!isUserLoggedIn() && <>
                                                                                <div className='sign-in-popup'>Please login for booking</div>
                                                                                <div className='sign-in-popup-arrow'></div>
                                                                            </>} */}
                                                                        </>
                                                                    }
                                                                </div>
                                                            );
                                                        })}
                                                        {!slotItem?.slot?.length &&
                                                            <div className='booking-slot-details booked'>
                                                                <h4 className='booking-slot-detail-title'>not <br /> available!</h4>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>

                                {/* next button */}
                                <div className={'booking-slot-next-btn' + (slotBoundary.isNextSlotBtnDisabled ? ' disabled' : '')} onClick={() => onNextGetSlot()}>
                                    <svg fill="none" viewBox="4 -1 32 32">
                                        <path d="M15.7792 10.235V8.975H16.6542L25.0542 14.155V15.905L16.6192 21.085H15.7792V19.825L23.5492 15.1V14.96L15.7792 10.235Z" fill="#B3B4B4"></path>
                                        <g clipPath="url(#clip0_196_6744)">
                                            <path d="M29.1515 30.5H29.3586L29.505 30.3536L35.3536 24.505L35.5 24.3586V24.1515V5.84852V5.64142L35.3536 5.49497L29.505 -0.353553L29.3586 -0.5H29.1515H10.8485H10.6414L10.495 -0.353553L4.64645 5.49497L4.5 5.64142V5.84852V24.1515V24.3586L4.64645 24.505L10.495 30.3536L10.6414 30.5H10.8485H29.1515ZM33.5293 23.5452L28.5424 28.5321H11.4548L6.46792 23.5452V6.45756L11.4576 1.46792H28.5424L33.5293 6.45749V23.5452Z" fill="#B3B4B4" stroke="#B3B4B4"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_196_6744">
                                                <rect width="30" height="30" fill="white" transform="translate(5)"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>


                        <div className={'booking-basket-container' + (isBasketMViewShow ? ' show' : '')}>
                            <div className='booking-basket-wrapper'>
                                <h4 className='booking-basket-title'>your basket</h4>
                                {!gridLayoutSetting.isWithBasket && isBasketMViewShow &&
                                    <div className='booking-basket-close-btn-wrap' onClick={() => setIsBasketMViewShow(false)}>
                                        <svg width="20" height="20" viewBox="0 0 32 32" fill="none">
                                            <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#B4B3B3" />
                                        </svg>
                                    </div>
                                }

                                <div className='booking-basket-items'>

                                    {myBasket.list?.map((basketItem, i) => {
                                        return <div className='booking-basket-item' key={i}>
                                            <div className='booking-basket-item-detail'>{`${basketItem?.previewDate} //`}</div>
                                            <div className='booking-basket-item-detail'>{`${basketItem?.startHs}:${basketItem?.startMs}-${basketItem?.endHs}:${basketItem?.endMs} //`}</div>
                                            <div className='booking-basket-item-detail'>{`${basketItem?.name} //`}</div>
                                            {basketItem.type === "MUSIC_STUDIO" && <div className='booking-basket-item-detail'>{`${basketItem?.peopleCount} People //`}</div>}
                                            {basketItem.type === "GAMES_ROOM" && <div className='booking-basket-item-detail'>{`£${basketItem?.price} X ${basketItem?.consoleCount}(Console${basketItem?.consoleCount > 1 ? 's' : ''}) //`}</div>}
                                            <div className='booking-basket-item-action-wrapper'>
                                                {basketItem.type === "GAMES_ROOM" ?
                                                    <div className='booking-basket-item-price'>{`£${basketItem?.price * basketItem?.consoleCount}`}</div>
                                                    :
                                                    <div className='booking-basket-item-price'>{`£${basketItem?.price}`}</div>
                                                }
                                                <div className='booking-basket-item-remove' onClick={() => onRemoveToBasket(i)}>x remove</div>
                                            </div>
                                        </div>
                                    })}

                                    {!myBasket.list?.length &&
                                        <div className='booking-basket-item'>
                                            <div className='booking-basket-empty-box'>
                                                <h4 className='booking-basket-empty-box-msg'>your basket is empty.</h4>
                                            </div>
                                        </div>
                                    }

                                </div>

                                {(myBasket?.list?.length > 0) && <>
                                    <div className='booking-basket-footer-wrapper'>
                                        <div className='booking-basket-totals'>
                                            <h4 className='booking-basket-total-title'>Total</h4>
                                            <h4 className='booking-basket-total-price'>{`£${myBasket?.total}`}</h4>
                                        </div>

                                        <div className='booking-basket-divider'></div>

                                        <div className='booking-basket-action-wrapper'>
                                            <button type='button' className={'booking-basket-action-btn' + (isCheckoutRequestAlive ? ' disabled' : '')} onClick={onCheckout} disabled={isCheckoutRequestAlive}>
                                                <h4 className='booking-basket-action-btn-text'>checkout</h4>
                                            </button>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>

                    </div>
                }
            </div>




            {/* booking pitch filter onscreen(as modal screen) for small screen based on togglePitchFilter button */}
            <div className={'booking-pitch-filter-m-wrapper' + (togglePitchFilter ? ' m-show' : '')}>
                <div className='booking-pitch-filter-m-close-btn-wrapper' onClick={() => onTogglePitchFilter()}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M32 4.03186L27.9681 0L16.0116 11.9566L4.03186 0L0 4.03186L11.9566 16.0116L0 27.9681L4.03186 32L16.0116 20.0434L27.9681 32L32 27.9681L20.0434 16.0116L32 4.03186Z" fill="#1E1E1E" />
                    </svg>
                </div>

                <div className='booking-pitch-filter-m-title-wrapper'>
                    <h4 className='booking-pitch-filter-m-title mb'>filter</h4>
                    <h4 className='booking-pitch-filter-m-title'>pitch type</h4>
                </div>
                <h5 className='booking-pitch-filter-m-sub-title'>pitch type</h5>

                <div className='booking-pitch-filter-m-items-wrapper'>
                    {
                        pitchFilterList.map((pitchFilterItem, i) => {
                            return (
                                <div className='booking-pitch-filter-m-item' key={i}>
                                    <h4 className='booking-pitch-filter-m-item-label'>{pitchFilterItem.label}</h4>
                                    <div className='booking-pitch-filter-m-item-checkbox-wrap' onClick={() => onPitchFilterChange(i)}>
                                        {selectedPitchType === pitchFilterItem.name ?
                                            <svg className='booking-pitch-filter-m-item-checkbox-icon' width="34" height="34" viewBox="0 0 162 162" fill="none">
                                                <g id="Group">
                                                    <path id="Vector" d="M132.319 0H29.6772L0 29.6772V132.319L29.6772 161.933H132.319L161.933 132.319V29.6772L132.319 0Z" fill="#DAFF00" />
                                                    <path id="Vector_2" d="M123.687 44.5474H115.243L65.4032 94.3875L46.8156 75.7368H38.2463L33.0796 80.9036V89.3468L61.1186 117.386H69.6248L128.664 58.2203L128.727 56.9602C128.79 54.6288 128.79 53.3056 128.79 51.0373V49.7141L123.687 44.5474Z" fill="#282727" />
                                                </g>
                                            </svg> :
                                            <svg className='booking-pitch-filter-m-item-checkbox-icon' width="34" height="34" viewBox="0 0 34 34" fill="none">
                                                <g id="Group 131">
                                                    <path id="Vector" d="M27.7592 0H6.25409L0 6.25409V27.7592L6.25409 34H27.7592L34 27.7592V6.25409L27.7592 0ZM8.34762 28.9119L5.08808 25.6524V8.34762L8.34762 5.08808H25.6391L28.8987 8.34762V25.6391L25.6391 28.8987H8.34762V28.9119Z" fill="#000000" />
                                                </g>
                                            </svg>
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default Booking;